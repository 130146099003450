import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';

export const Wrapper = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 15px 30px;

  @media (min-width: ${screens.md}) {
    padding: 25px 40px;
  }
`;

export const Content = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.875rem;
  font-weight: bold;
  font-style: italic;

  @media (min-width: ${screens.md}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const InfoCol = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    width: 50%;
  }
  > div:first-child {
    text-align: right;
    margin-right: 1rem;
  }
  > div:last-child {
    text-align: left;
    color: ${({ theme }) => theme.colors.red};
  }

  @media (min-width: ${screens.md}) {
    display: block;

    > div {
      width: auto;
    }
    > div:first-child {
      text-align: left;
      margin-right: 0;
    }
  }
`;

export const InfoRow = styled('div')`
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: ${screens.md}) {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    > div:first-child {
      margin-right: 150px;
    }
  }
`;