import React from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import { isExternal } from '../utils/url';

const Link = styled('a')`
  text-decoration: none;
  color: inherit;
`;

function FlexibleLink({ to, children, className }) {
  const onClick = (event) => {
    if (to && !isExternal(to)) {
      event.preventDefault();
      navigate(to);
    }
  };

  return (
    <Link
      className={className}
      rel="noopener noreferrer"
      target="_blank"
      onClick={onClick}
      href={to}
    >
      {children}
    </Link>
  );
}

export default FlexibleLink;
