import React, { Fragment } from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { PoseGroup } from 'react-pose';
import { Link } from '@reach/router';
import cc from 'classcat';
import debounce from 'lodash/debounce';

import {
  getArtists,
  getArtistFilters,
  getArtistsPageSettings
} from '../../api/api';
import ArtistsList from '../../components/ArtistsList/ArtistsList';
import ArtistsFilter from '../../components/ArtistsFilter/ArtistsFilter';
import ArtistsSearch from '../../components/ArtistsSearch/ArtistsSearch';
import ScrollDirection from '../../components/ScrollDirection';
import Toggle from '../../components/Toggle';
import Page from '../../components/Page/Page';
import { setAddedArtists } from '../../actions/artists';
import { getLocationOrigin } from '../../utils/url';
import { copyToClipboard } from '../../utils/clipboard';

// icons
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';

// styles
import {
  TextBox,
  Fade,
  PageBoxHeading,
  PageBoxHeadingWrapper
} from '../../styles/shared-styles';
import * as S from './styles';

const initialSelection = {
  group: null,
  termId: null
};

class Artists extends React.Component {
  state = {
    filters: null,
    artists: [],
    selection: initialSelection,
    searchTerm: '',
    searchFocused: false,
    searchIsOpen: false,
    addedListIsOpen: false,
    filterGroupIsOpen: false,
    linkCopied: false,
    featured: []
  };

  async componentDidMount() {
    // Load the date from the API
    try {
      const [artists, filters, featuredData] = await Promise.all([
        getArtists(),
        getArtistFilters(),
        getArtistsPageSettings()
      ]);

      this.setState({
        artists,
        filters,
        featured:
          featuredData && featuredData.featured ? featuredData.featured : []
      });
    } catch (err) {
      console.log(err);
    }

    this.restoreAddedList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.restoreAddedList();
    }
  }

  restoreAddedList = () => {
    const queryString = qs.parse(this.props.location.search);
    if (!queryString.list) return;

    // Remove invalid numbers, duplicates, etc
    let addedList = queryString.list
      .split(':')
      .filter(
        (v, i, self) => v.length > 0 && !isNaN(v) && self.indexOf(v) === i
      )
      .map(Number);

    // Remove non-existent artist ids
    if (this.state.artists.length > 0) {
      const validArtistIds = this.state.artists.map((a) => a.id);
      addedList = addedList.filter((v) => validArtistIds.indexOf(v) > -1);
    }

    if (addedList.length > 0) {
      this.props.setAddedArtists(addedList);
      // Open the added list
      this.setState({ addedListIsOpen: true });
    }
  };

  setSelection = (selection = {}) => {
    this.setState({ selection: { ...initialSelection, ...selection } });
  };

  onOpenFilterGroup = (nextGroup) => {
    this.setState({ filterGroupIsOpen: nextGroup !== null });
  };

  setSearchTerm = debounce((searchTerm) => {
    this.setState({ searchTerm });
  }, 200);

  onSearchFocus = () => {
    this.setState({ searchFocused: true });
  };

  onSearchBlur = () => {
    this.setState({ searchFocused: false });
  };

  onSearchToggle = (event) => {
    event.preventDefault();

    this.setState({ searchIsOpen: !this.state.searchIsOpen });
  };

  onAddedListToggle = (event) => {
    event.preventDefault();
    const addedListIsOpen = !this.state.addedListIsOpen;

    // Reset the selection and copied state if the added artists list will be open
    if (addedListIsOpen) {
      this.setState({
        addedListIsOpen,
        selection: initialSelection,
        linkCopied: false
      });
    } else {
      this.setState({ addedListIsOpen });
    }
  };

  renderControls() {
    const { filters, searchIsOpen, addedListIsOpen } = this.state;

    if (!filters || !filters.genre || !filters.vibe) {
      return null;
    }

    return (
      <Fragment>
        <ArtistsSearch
          isActive={searchIsOpen}
          className={cc({ 'mobile-only': !searchIsOpen })}
          onBlur={this.onSearchBlur}
          onFocus={this.onSearchFocus}
          onSearchChange={this.setSearchTerm}
        />
        <PoseGroup>
          {!addedListIsOpen && (
            <Fade key="artistsFilter" duration={500}>
              <ArtistsFilter
                className={cc({ 'mobile-only': searchIsOpen })}
                selection={this.state.selection}
                filters={filters}
                onOpenGroup={this.onOpenFilterGroup}
                onSelection={this.setSelection}
              />
            </Fade>
          )}
        </PoseGroup>
      </Fragment>
    );
  }

  renderToggles() {
    const { searchIsOpen, addedListIsOpen } = this.state;
    return (
      <S.Toggles className="mobile-hidden">
        <Toggle
          onClick={this.onSearchToggle}
          icon={searchIsOpen ? CloseIcon : SearchIcon}
          value={searchIsOpen ? 'close' : 'search'}
        />
        <Toggle
          onClick={this.onAddedListToggle}
          icon={addedListIsOpen ? CloseIcon : ListIcon}
          value={addedListIsOpen ? 'hide my list' : 'view my list'}
        />
      </S.Toggles>
    );
  }

  getListUrl = () => {
    const { location, addedArtists } = this.props;
    const pathname = `/artists?list=${addedArtists.join(':')}`;

    return {
      pathname,
      name: `${location.host}/artists`
    };
  };

  copyAddedListUrl = () => {
    copyToClipboard(getLocationOrigin() + this.getListUrl().pathname);

    this.setState({ linkCopied: true });
  };

  render() {
    const controls = this.renderControls();
    const ready = this.state.filters && this.state.artists.length > 0;
    const myAddedListUrl = this.getListUrl();

    return (
      <Page title="Artists" ready={ready}>
        {ready && (
          <S.PageWrapper>
            <S.PageIntro>
              {this.renderToggles()}
              <PageBoxHeadingWrapper className="page-header">
                <PageBoxHeading>artists</PageBoxHeading>
              </PageBoxHeadingWrapper>
              <S.IntroductionBlock>
                <TextBox className="small">
                  {this.state.addedListIsOpen ? (
                    <Fragment>
                      <p>
                        Share your list with your fellow
                        <br />
                        creatives using the link below.
                      </p>
                      <p>
                        <S.ListButton onClick={this.copyAddedListUrl}>
                          {myAddedListUrl.name}
                        </S.ListButton>
                      </p>
                      <p>
                        <S.CopyListButton
                          type="button"
                          onClick={this.copyAddedListUrl}
                        >
                          {this.state.linkCopied ? 'Link Copied!' : 'Copy Link'}
                        </S.CopyListButton>
                      </p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p>
                        Here are our artists. You can filter by genre or vibe
                        using the filter function. You can also create a custom
                        list using the “add to my list” function.
                      </p>
                      <p>
                        Can't find what you’re looking for?
                        <br />
                        <Link to="/contact">Let us do the work for you</Link>
                      </p>
                    </Fragment>
                  )}
                </TextBox>
              </S.IntroductionBlock>
            </S.PageIntro>

            <div className="mobile-hidden">{controls}</div>
            {this.state.artists.length > 0 && (
              <ArtistsList
                addedArtists={this.props.addedArtists}
                addedListIsOpen={this.state.addedListIsOpen}
                selection={this.state.selection}
                filters={this.state.filters}
                artists={this.state.artists}
                featured={this.state.featured}
                searchTerm={this.state.searchTerm}
              />
            )}
            <div className="mobile-only">
              <ScrollDirection>
                {({ direction }) => {
                  // Dont close the controls if:
                  // - the direction is up
                  // - the search field is focused
                  // - a filter group is expanded
                  const isVisible =
                    direction === 'up' ||
                    this.state.searchFocused ||
                    this.state.filterGroupIsOpen;
                  return (
                    <S.MobileControlsWrapper
                      className={cc([
                        isVisible ? 'visible' : 'hidden',
                        { scrollable: this.state.filterGroupIsOpen }
                      ])}
                    >
                      <S.MobileControls className="mobile-controls">
                        {controls}
                      </S.MobileControls>
                    </S.MobileControlsWrapper>
                  );
                }}
              </ScrollDirection>
            </div>
          </S.PageWrapper>
        )}
      </Page>
    );
  }
}

const select = ({ artists }) => ({
  addedArtists: artists.added
});

export default connect(select, { setAddedArtists })(Artists);
