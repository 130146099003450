import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { BaseBoxHeading, basicTextStyle } from '../../styles/shared-styles';
import Toggle from '../Toggle';

export const Spacer = styled('div')`
  display: none;
  @media (min-width: ${screens.xl}) {
    display: block;
    padding-bottom: calc(100vh - 80px);
  }
`;

export const Wrapper = styled('div')`
  position: relative;
  min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .artist-image {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.scrolled-past {
    @media (min-width: ${screens.xl}) {
      .artist-image {
        opacity: 0.25;
      }
      .scroll-btn {
        opacity: 0;
      }
    }
  }

  @media (min-width: ${screens.md}) {
    min-height: calc(100vh - 80px);
    padding: 40px 80px 40px;

    &:before {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      height: 100%;
      width: 60%;
      background-image: linear-gradient(to left, transparent 11%, var(--color-darkblue) 100%);
      z-index: 1;
    }

    @media (min-width: ${screens.xl}) {
      /* 92 + 40 = 132 is the header + footer */
      min-height: calc(100vh - 132px);
      position: fixed;
    }
  }
`;

export const Inner = styled('div')`
  margin-top: auto;
  position: relative;
  z-index: 2;
  color: ${({ theme }) => theme.colors.darkblue};

  @media (min-width: ${screens.md}) {
    color: ${({ theme }) => theme.colors.grey};
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
  }

  @media (min-width: ${screens.lg}) {
    width: 50%;
  }

  @media (min-width: ${screens.xl}) {
    width: calc(50% - 80px);
  }
`;

export const TextContent = styled('div')`
  ${basicTextStyle}
  line-height: 1.6;
  text-align: justify;

  @media (min-width: ${screens.md}) {
    font-size: 1.125rem;
  }

  @media (min-width: ${screens.lg}) {
    padding-right: 3rem;
  }

  @media (min-width: ${screens.xl}) {
    padding-right: 0;
  }
`;

export const ArtistName = styled(BaseBoxHeading)`
  margin-bottom: 1.25rem;
  
  @media (min-width: ${screens.md}) {
    margin-bottom: 1.75rem;
    font-size: 4.5rem;
    text-transform: none;
  }
`;

export const Separator = styled('div')`
  margin: 1.5rem 0;
  width: 100%;
  max-width: 286px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.darkblue};

  @media (min-width: ${screens.md}) {
    background-color: ${({ theme }) => theme.colors.grey};
    height: 3px;
  }
`;

export const SocialCounts = styled('div')`
  display: flex;
  .social-count {
    color: ${({ theme }) => theme.colors.grey};
  }
  .social-count + .social-count {
    margin-left: 1.5rem;

    @media (min-width: ${screens.md}) {
      margin-left: 1.75rem;
    }
  }
`;

export const AddedToggle = styled(Toggle)`
  color: ${({ theme }) => theme.colors.grey};
  font-style: normal;
  margin: auto 0 1rem;
  padding-top: 3rem;

  svg {
    width: 24px;
  }
`;

export const SocialIcon = styled('div')`
  svg {
    height: 30px;
    width: 30px;
    path {
      fill: currentColor;
    }
  }
`;
