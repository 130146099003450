import { css, keyframes } from 'styled-components/macro';
import { colors } from './theme';
import range from 'lodash/range';

export const getLinearGradient = (deg = 32, fromColor = 'red', toColor = 'darkblue') => {
  return `linear-gradient(
    ${deg}deg,
    ${colors[fromColor]},
    ${colors[toColor]}
  )`;
};

export const aspectRatio = (ratio, diffOnly) => {
  if (diffOnly) {
    return css`
      &:before {
        padding-top: ${ratio}%;
      }
    `;
  }

  return css`
    position: relative;
    &:before {
      display: block;
      content: '';
      height: 0;
      width: 100%;
      padding-top: ${ratio}%;
    }
  `;
};

export const getColoredBoxStyle = (
  backgroundColor = 'red',
  boxShadowColor = 'darkblue',
  diffOnly
) => {
  const styles = css`
    color: #fff;
    font-weight: bold;
    font-style: italic;
    background-color: ${colors[backgroundColor]};
    transition-property: box-shadow, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;

    &:hover:not(:disabled) {
      box-shadow: 0 0 0 ${colors[boxShadowColor]};
    }
  `;

  return {
    small: css`
      ${!diffOnly && styles}
      padding: 0.15rem 0.5rem 0.25rem;
      box-shadow: 3px -3px 0 ${colors[boxShadowColor]};
      font-size: 0.875rem;

      &:hover {
        transform: translate(1.5px, -1.5px);
      }
    `,
    normal: css`
      ${!diffOnly && styles}
      padding: 0.25rem 0.75rem 0.5rem;
      box-shadow: 5px -5px 0 ${colors[boxShadowColor]};
      font-size: 1.5rem;

      &:hover:not(:disabled) {
        transform: translate(2.5px, -2.5px);
      }
    `,
    mediumLarge: css`
      ${!diffOnly && styles}
      padding: 0.5rem 1.5rem 0.9rem 1.25rem;
      box-shadow: 8px -8px 0 ${colors[boxShadowColor]};
      font-size: 2rem;

      &:hover {
        transform: translate(4px, -4px);
      }
    `,
    large: css`
      ${!diffOnly && styles}
      padding: 0.5rem 1.5rem 0.9rem 1.25rem;
      box-shadow: 10px -10px 0 ${colors[boxShadowColor]};
      font-size: 3rem;

      &:hover {
        transform: translate(5px, -5px);
      }
    `
  };
};

export const getShakeAnimation = (radius = 2, step = 4) => {
  const leftTimes = range(0, 101, step)
    .map((i) => `${i}%`)
    .join(',');
  const rightTimes = range(step / 2, 101, step)
    .map((i) => `${i}%`)
    .join(',');

  return keyframes`
    ${leftTimes} {
      transform: translateX(${radius}px)
    }  
    ${rightTimes} {
      transform: translateX(-${radius}px)
    }
  `;
};
