import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { PaddingWrapper } from '../../styles/shared-styles';

export const PageContainer = styled(PaddingWrapper)`
  position: relative;
  z-index: 8;
  background-color: ${({ theme }) => theme.colors.grey};
  margin: 0;

  &.mt-0 {
    margin-top: 0;
  }
`;

export const PageContent = styled('div')`
  color: ${({ theme }) => theme.colors.blue};
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 60px);

  > div {
    width: 100%;
    flex-wrap: nowrap;
  }

  @media (min-width: ${screens.md}) {
    height: calc(100vh - 80px);

    > div {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1rem;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  h2 {
    margin: 0 0 1rem;
    color: inherit;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 1.25;

    @media (min-width: ${screens.md}) {
      font-size: 3.5rem;
    }

    @media (min-width: ${screens.lg}) {
      font-size: 4rem;
    }

    @media (min-width: ${screens.xl}) {
      font-size: 4.5rem;
    }
  }
`;

export const Column = styled('div')`
  padding: 2rem;
  flex: 1;

  @media (min-width: ${screens.md}) {
    padding: 4rem 4rem 2.5rem;

    textarea {
      max-height: calc(100vh - 650px);
      min-height: 1.5em;
    }
  }

  @media (min-width: ${screens.xl}) {
    padding: 5rem 5rem 2.5rem;

    textarea {
      max-height: calc(100vh - 600px);
    }
  }
`;

export const LeftColumn = styled(Column)`
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  position: relative;

  .background {
    z-index: -1;
  }

  h2 {
    font-style: normal;
  }
`;
