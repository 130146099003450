import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { PaddingWrapper } from '../../styles/shared-styles';
import { pin } from '../../styles/utils';

export const NavWrapper = styled(PaddingWrapper)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  z-index: 12;
  /* Fix for relative positioned elements jumping in iOS Safari */
  height: 100vh;

  &.fullscreen {
    z-index: 100;
  }

  &:before {
    content: '';
    ${pin}
    z-index: 9;
    border-left: 30px solid ${({ theme }) => theme.colors.grey};
    border-right: 30px solid ${({ theme }) => theme.colors.grey};

    @media (min-width: ${screens.md}) {
      border-width: 40px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 13;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.grey};

    @media (min-width: ${screens.md}) {
      height: 40px;
    }
  }
`;

export const NavInner = styled('nav')`
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: 0;
  z-index: 12;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 30px;
  height: 100%;
  writing-mode: vertical-rl;

  @media (min-width: ${screens.md}) {
    width: 40px;
  }
`;

export const NavItem = styled('div')`
  margin: 0.5rem 0;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkblue};
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    padding: 3px 0 3px 3px;
  }

  a.sweep-on-hover {
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
    position: relative;
    transition: 0.4s color;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${({ theme }) => theme.colors.darkblue};
      transform: scaleY(0);
      transform-origin: 0 0%;
      transition: transform 0.4s ease-out;
    }

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.grey};
      &::before {
        transform: scaleY(1);
      }
    }
  }

  a.current {
    box-shadow: inset 3px 0 0 ${({ theme }) => theme.colors.darkblue};
  }

  a.color-red {
    color: ${({ theme }) => theme.colors.red};
    &.current {
      box-shadow: inset 3px 0 0 ${({ theme }) => theme.colors.red};
    }
    &.sweep-on-hover::before {
      background: ${({ theme }) => theme.colors.red};
    }
  }

  @media (min-width: ${screens.md}) {
    margin: 1rem 0;
  }

  @media (min-width: ${screens.lg}) {
    margin: 1.5rem 0;
    font-size: 1.125rem;
  }
`;
