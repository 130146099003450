import styled from 'styled-components/macro';

export const PlaylistWrapper = styled('div')`
  display: flex;
  font-size: 1.125rem;
  align-items: center;
  line-height: 1.25;
  margin: 0.5rem 0 auto;

  .song-toggle {
    .circle,
    svg {
      height: 50px;
      width: 50px;
    }

    .circle {
      background-color: ${({ theme }) => theme.colors.grey};
    }
  }

  .song-title {
    font-weight: bold;
    padding-bottom: 2px;
  }
`;

export const Controls = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;

  .song-next {
    margin-left: 0.75rem;
  }
  .song-prev {
    margin-right: 0.75rem;
  }

  .song-next, .song-prev {
    transform: translateZ(0) scale(1);
    transform-origin: 50%;
    transition: transform 0.25s ease-out;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
