import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import jump from 'jump.js';
import { useToggleBodyClass } from '../../hooks/common';
import { ScrollButton } from '../../styles/shared-styles';
import * as S from './styles';

const openingDuration = 1200;

function Intro({ autoPlay, skipOpening, shouldScrollDown, videoUrl, thumbnail, onOpeningEnded }) {
  const [showBorder, setShowBorder] = useState(skipOpening);
  const [openingEnded, setOpeningEnded] = useState(skipOpening);
  const [showScrollButton, setShowScrollButton] = useState(skipOpening);

  const videoRef = createRef();

  // Update body class according to the border state state
  useToggleBodyClass(openingEnded, ['overlay-closed', 'overlay-open']);

  // Scroll down automatically if requested
  useEffect(() => {
    if (skipOpening && shouldScrollDown) {
      setTimeout(() => {
        jump('#newsfeed', {
          duration: 700,
          offset: -30
        });
      }, 300);
    }
  }, [shouldScrollDown]);

  const onPlay = () => {
    if (!skipOpening) {
      setTimeout(() => {
        setShowBorder(true);
      }, 1000);

      setTimeout(() => {
        setOpeningEnded(true);
        if (onOpeningEnded) onOpeningEnded();
      }, 1000 + openingDuration);

      setTimeout(() => {
        setShowScrollButton(true);
      }, 5000);
    }
  };

  const scrollToContent = (event) => {
    if (event) {
      event.preventDefault();
    }

    const videoEl = videoRef.current;
    if (videoEl) {
      videoEl.pause();
    }

    jump('#newsfeed', {
      duration: 700,
      offset: -30
    });
  };

  const togglePlayback = () => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    if (videoEl.paused) {
      videoEl.play();
    } else {
      videoEl.pause();
    }
  };

  return (
    <S.IntroContainer className={openingEnded ? 'opening-ended' : undefined}>
      <S.VideoContainer onClick={togglePlayback} className="video-container">
        <S.Video
          ref={videoRef}
          onPlay={onPlay}
          muted={true}
          autoPlay={autoPlay}
          poster={thumbnail ? thumbnail : undefined}
          preload="auto"
          playsInline
          loop
        >
          <source src={videoUrl} type="video/mp4" />
        </S.Video>
      </S.VideoContainer>
      <S.Border
        withParent={false}
        pose={openingEnded ? 'hidden' : 'visible'}
        className={showBorder ? 'visible' : undefined}
        openingDuration={openingDuration}
      />
      <ScrollButton
        className={cc(['scroll-btn', { 'is-visible': showScrollButton }])}
        type="button"
        onClick={scrollToContent}
      >
        scroll
      </ScrollButton>
    </S.IntroContainer>
  );
}

Intro.defaultProps = {
  autoPlay: true,
  skipOpening: false,
  shouldScrollDown: false
};

Intro.propTypes = {
  autoPlay: PropTypes.bool,
  skipOpening: PropTypes.bool,
  shouldScrollDown: PropTypes.bool,
  onOpeningEnded: PropTypes.func
};

export default Intro;
