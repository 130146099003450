import { Component } from 'react';

class ScrollUp extends Component {
  componentDidMount() {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return this.props.children;
  }
}

export default ScrollUp;
