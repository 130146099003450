import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as S from './styles';
import { Box, BoxHeading, SmallColoredBox, StyledError } from '../../styles/shared-styles';
import { isEmail } from '../../utils/validate';

function NewsletterBlock({ title, text, renderedGif }) {
  const [success, setSuccess] = useState(false);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(false);
    setSuccess(true);
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = 'The email is required';
    } else if (!isEmail(value)) {
      error = 'Invalid email address';
    }
    return error;
  };

  return (
    <SmallColoredBox className="align-right newsletter-block">
      {renderedGif}
      <S.Header>
        <BoxHeading>{title || 'newsletter'}</BoxHeading>
      </S.Header>
      <S.Content>
        <Box as="p" mb={4}>
          {text}
        </Box>
        {success ? (
          <S.InfoMessage>
            thanks!{' '}
            <span aria-label="success" role="img">
              👍
            </span>
          </S.InfoMessage>
        ) : (
          <Formik
            initialValues={{ email: '' }}
            onSubmit={onSubmit}
            render={(formProps) => (
              <Form>
                <S.FormFieldCombined>
                  <Field validate={validateEmail} name="email" type="email" placeholder="Email" />
                  <button disabled={formProps.isSubmitting} type="submit">
                    Subscribe
                  </button>
                </S.FormFieldCombined>
                <ErrorMessage name="email" className="color-grey" component={StyledError} />
              </Form>
            )}
          />
        )}
      </S.Content>
    </SmallColoredBox>
  );
}

export default NewsletterBlock;
