import styled from 'styled-components/macro';
import FlexibleLink from '../FlexibleLink';
import { colors, screens } from '../../styles/theme';

export const Container = styled.div`
  margin: 60px 0;
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  line-height: 1.16;
  letter-spacing: 0.066px;
  margin: 16px 0 20px;

  &::after {
    content: '';
    display: block;
    margin-top: 20px;
    border-bottom: 3px solid #002339;
    width: 105px;
  }
`;

export const TextContent = styled.div`
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 25px;
`;

export const Link = styled(FlexibleLink)`
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.16;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${colors.red};
  padding: 10px 20px 12px;
  border: 3px solid ${colors.red};
  text-transform: lowercase;
  font-weight: bold;
  transition: all 0.3s ease-in;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.red};
    color: ${colors.grey};
  }

  @media (min-width: ${screens.sm}) {
    font-size: 1.5rem;
    padding: 20px 40px 25px;
  }
`;
