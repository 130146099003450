/*
  Originally by thebuilder: https://github.com/thebuilder/react-scroll-percentage

  Fixed a bug when an element appears in view with a wrong scroll percentage
  Added `disabled` prop to force scroll updates (fixes Safari bug)
*/

import React from 'react';
import InView from 'react-intersection-observer';
import { unwatch, watch } from './scroll';

class ScrollPercentage extends React.PureComponent {
  static defaultProps = {
    tag: 'div',
    threshold: 0
  };

  /**
   * Get the correct viewport height. If rendered inside an iframe, grab it from the parent
   */
  static viewportHeight() {
    return global.parent ? global.parent.innerHeight : global.innerHeight || 0;
  }

  static calculatePercentage(bounds, threshold = 0) {
    const vh = ScrollPercentage.viewportHeight();
    const offsetTop = threshold * vh * 0.25;
    const offsetBottom = threshold * vh * 0.25;

    return (
      1 -
      Math.max(
        0,
        Math.min(1, (bounds.bottom - offsetTop) / (vh + bounds.height - offsetBottom - offsetTop))
      )
    );
  }

  state = {
    percentage: 0,
    inView: false
  };

  componentDidMount() {
    if (this.props.disabled) {
      return;
    }
    // Start by updating the scroll position, so it correctly reflects the elements start position
    this.handleScroll();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.disabled) {
      return;
    }

    if (
      this.props.onChange &&
      (prevState.percentage !== this.state.percentage || prevState.inView !== this.state.inView)
    ) {
      this.props.onChange(this.state.percentage, this.state.inView);
    }

    if (prevProps.disabled && !this.props.disabled) {
      this.handleScroll();
    }

    if (prevState.inView !== this.state.inView) {
      this.monitorScroll(this.state.inView);
      
      // Update the scroll position if the element is in view
      if (this.state.inView) {
        this.handleScroll();
      }
    }
  }

  componentWillUnmount() {
    this.monitorScroll(false);
  }

  node = null;

  monitorScroll(enable) {
    if (enable) {
      watch(this.handleScroll);
    } else {
      unwatch(this.handleScroll);
    }
  }

  handleInView = (inView) => {
    this.setState({ inView });
  };

  handleNode = (observer) => (this.node = observer && observer.node);

  handleScroll = () => {
    if (!this.node) return;
    const { threshold } = this.props;
    const bounds = this.node.getBoundingClientRect();
    const percentage = ScrollPercentage.calculatePercentage(bounds, threshold);

    if (percentage !== this.state.percentage) {
      this.setState({
        percentage
      });
    }
  };

  renderInner = ({ inView, ref }) => {
    const { children, onChange, threshold, innerRef, tag, disabled, ...props } = this.props;

    // Create a wrapping element
    return React.createElement(
      tag || 'div',
      {
        ref: innerRef
          ? (node) => {
              innerRef(node);
              ref(node);
            }
          : ref,
        ...props
      },
      typeof children === 'function'
        ? children({ percentage: this.state.percentage, inView })
        : children
    );
  };

  render() {
    return (
      <InView onChange={this.handleInView} threshold={this.props.threshold} ref={this.handleNode}>
        {this.renderInner}
      </InView>
    );
  }
}

export default ScrollPercentage;
