import React from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import TintedImage from '../TintedImage/TintedImage';
import { Image } from '../../styles/shared-styles';
import { projectEffect } from '../../styles/effects';
import { parseHtmlEntities } from '../../utils/htmlEntities';
import * as S from './styles';

export default function ProjectItem({ data }) {
  const renderMedia = (media) => {
    switch (media.type) {
      case 'video_external':
        const video = media.video_external_url;
        if (!video) return null;

        return (
          <div>
            <VideoPlayer
              className="background-red"
              videoUrl={video.url}
              thumbnailUrl={video.thumbnail_url}
              posterEffects={projectEffect}
              buttonClassName="circle-white is-smaller"
            />
          </div>
        );
      case 'image':
        if (!media.image) return null;

        return (
          <TintedImage effects={projectEffect}>
            <Image src={media.image.sizes.large || media.image.url} />
          </TintedImage>
        );
      default:
        return null;
    }
  };

  const renderLink = (link) => {
    if (link.type === 'artist') {
      return (
        <S.Link to={`/artists/${link.artist.post_name}`}>
          {link.label
            ? link.label
            : `view ${parseHtmlEntities(link.artist.post_title)}`}
        </S.Link>
      );
    } else if (link.url) {
      return <S.Link to={link.url}>{link.label || 'view'}</S.Link>;
    }

    return null;
  };

  const { text, media, link } = data.acf;

  return (
    <S.Container>
      {renderMedia(media)}
      <S.Title dangerouslySetInnerHTML={{ __html: data.title.rendered }} />
      <S.TextContent dangerouslySetInnerHTML={{ __html: text }} />
      {renderLink(link)}
    </S.Container>
  );
}
