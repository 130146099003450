import styled from 'styled-components/macro';
import { Box } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';

export const ImageFloater = styled(Box)`
  display: none;
  width: 229px;
  height: 229px;

  @media (min-width: ${screens.md}) {
    display: block;
  }
`;

ImageFloater.defaultProps = {
  my: 150
};
