import React, { Component, Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getArtist } from '../../api/api';
import { PaddingWrapper } from '../../styles/shared-styles';
import ArtistOverview from '../../components/ArtistOverview/ArtistOverview';
import ArtistStory from '../../components/ArtistStory/ArtistStory';
import ArtistContact from '../../components/ArtistContact/ArtistContact';
import Page from '../../components/Page/Page';
import Parallax from '../../components/Parallax/Parallax';
import { parseHtmlEntities } from '../../utils/htmlEntities';

const parallaxProps = {
  start: 25,
  end: 100,
  transform: {
    offsets: {
      yMin: -40,
      yMax: 0
    },
    unit: 'px'
  }
};

class Artist extends Component {
  state = {
    artist: null,
    overlayIsOpen: false,
    activeVideoId: null,
    isPlayingAudio: false
  };

  async componentDidMount() {
    try {
      const artist = await getArtist(this.props.slug);
      this.setState({ artist });
    } catch (err) {
      console.log(err);
    }
  }

  openOverlay = () => {
    this.setState({ overlayIsOpen: true });
    document.body.classList.add('overlay-open');
  };

  closeOverlay = () => {
    this.setState({ overlayIsOpen: false });
    document.body.classList.remove('overlay-open');
  };

  onVideoPlayRequest = (activeVideoId) => {
    this.setState({ activeVideoId, isPlayingAudio: false });
  };

  onAudioPlayUpdate = () => {
    this.setState({ isPlayingAudio: true, activeVideoId: null })
  }

  render() {
    const { artist, activeVideoId } = this.state;
    const ready = artist !== null;
    const artistName = ready ? parseHtmlEntities(artist.title.rendered) : undefined;

    return (
      <Fragment>
        <Page ready={ready} title={artistName} renderContactPage={false}>
          {ready && (
            <PaddingWrapper>
              <Parallax {...parallaxProps}>
                {({ style: parallaxStyle }) => (
                  <Fragment>
                    <ArtistOverview
                      parallaxStyle={parallaxStyle}
                      artist={artist}
                      isPlayingAudio={this.state.isPlayingAudio}
                      onAudioPlayUpdate={this.onAudioPlayUpdate}
                    />
                    {!isEmpty(artist.acf.page_content) && (
                      <ArtistStory
                        items={artist.acf.page_content}
                        activeVideoId={activeVideoId}
                        onVideoPlayRequest={this.onVideoPlayRequest}
                      />
                    )}
                  </Fragment>
                )}
              </Parallax>
            </PaddingWrapper>
          )}
        </Page>
        {ready && (
          <ArtistContact
            isOpen={this.state.overlayIsOpen}
            artistName={artistName}
            onRequestClose={this.closeOverlay}
            onRequestOpen={this.openOverlay}
            contact={artist.acf.contact_info}
          />
        )}
      </Fragment>
    );
  }
}

export default Artist;
