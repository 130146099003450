import posed from 'react-pose';
import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';

export const IntroContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.grey};
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding: 30px;

  .scroll-btn {
    z-index: 11;
    bottom: 30px;
    opacity: 0;
    transition: opacity 0.5s ease-out;

    &.is-visible {
      opacity: 1;
    }
  }

  @media (min-width: ${screens.md}) {
    padding: 40px;

    .scroll-btn {
      bottom: 35px;
    }
  }

  &.opening-ended {
    z-index: auto;
  }
`;

export const VideoContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const Video = styled('video')`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const BorderFade = posed.div({
  visible: {
    opacity: 1,
    applyAtStart: { display: 'block' },
    transition: {
      duration: 1000,
      ease: 'easeOut'
    }
  },
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: {
      duration: 500,
      ease: 'easeOut'
    }
  }
});

export const Border = styled(BorderFade)`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0px solid ${({ theme }) => theme.colors.grey};
  z-index: 13;

  &.visible {
    border-width: 30px;
    transition: border-width ${(props) => props.openingDuration || 1000}ms ease-out;

    @media (min-width: ${screens.md}) {
      border-width: 40px;
    }
  }
`;
