import React from 'react';
import * as S from './styles';
import TintedImage from '../TintedImage/TintedImage';
import {
  TextBox,
  BackgroundImage,
  BigHeading,
  ButtonLikeLink,
  Pin
} from '../../styles/shared-styles';
import { rosterViewEffect } from '../../styles/effects';
import { getBackgroundUrl } from '../../utils/image';
import { stripHtmlStyleAttribute } from '../../utils/common';

function RosterView({ title, content, image, button }) {
  return (
    <S.RosterTeaser>
      <Pin>
        <TintedImage borderSize={30} effects={rosterViewEffect} className="pinned">
          <BackgroundImage url={getBackgroundUrl(image)} />
        </TintedImage>
        <S.RosterContent>
          <div className="teaser-main">
            <BigHeading className="teaser-heading color-darkblue">{title}</BigHeading>
            <TextBox
              className="teaser-content small"
              dangerouslySetInnerHTML={{ __html: stripHtmlStyleAttribute(content) }}
            />
          </div>
          {button.name && (
            <div className="teaser-button">
              <ButtonLikeLink href={button.url}>{button.name}</ButtonLikeLink>
            </div>
          )}
        </S.RosterContent>
      </Pin>
    </S.RosterTeaser>
  );
}

export default RosterView;
