import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components/macro';
import { NarrowContainer, PaddingWrapper } from '../styles/shared-styles';

const P = styled.p`
  font-weight: bold;
  text-align: center;
`;

export default function NotFound() {
  return (
    <PaddingWrapper>
      <NarrowContainer>
        <P>
          Not found. <Link to="/">Back to the front page.</Link>
        </P>
      </NarrowContainer>
    </PaddingWrapper>
  );
}
