import React from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';
import intersection from 'lodash/intersection';
import { connect } from 'react-redux';
import ArtistListItem from './ArtistListItem';
import { addArtist, removeArtist } from '../../actions/artists';
import * as S from './styles';

const includesSearchTerm = (searchTerm, value) => {
  return value.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
};

const getSuggestedTerms = (searchTerm, terms) => {
  return terms.filter((t) => includesSearchTerm(searchTerm, t.name)).map((t) => t.term_id);
};

function Artists(props) {
  let { artists, selection, searchTerm, addedListIsOpen, featured } = props;

  // Filter out artists not added to the list
  if (addedListIsOpen) {
    artists = artists.filter((a) => props.addedArtists.indexOf(a.id) > -1);
  }

  // Filter out unselected artists
  if (selection.group && selection.termId) {
    artists = artists.filter((artist) => {
      const artistTerms = artist[selection.group] || [];
      return artistTerms.indexOf(selection.termId) > -1;
    });
  }

  if (searchTerm) {
    const genreTerms = getSuggestedTerms(searchTerm, props.filters.genre);
    const vibeTerms = getSuggestedTerms(searchTerm, props.filters.vibe);

    artists = artists.filter((artist) => {
      if (genreTerms.length > 0 && intersection(artist.genre, genreTerms).length > 0) {
        return true;
      }

      if (vibeTerms.length > 0 && intersection(artist.vibe, vibeTerms).length > 0) {
        return true;
      }

      return includesSearchTerm(searchTerm, artist.title.rendered);
    });
  }

  // Render featured artists if neither filters, search nor the favorites are activated
  if (
    featured.length > 0 &&
    !searchTerm &&
    !addedListIsOpen &&
    !selection.group &&
    !selection.termId
  ) {
    artists = featured.map((id) => artists.find((artist) => artist.id === id)).filter((a) => a);
  }

  return (
    <S.ArtistsList>
      <PoseGroup>
        {artists.map((artist) => (
          <S.ArtistWrapper key={artist.id} pose="flip">
            <ArtistListItem
              isAdded={props.addedArtists.indexOf(artist.id) > -1}
              artist={artist}
              addArtist={props.addArtist}
              removeArtist={props.removeArtist}
            />
          </S.ArtistWrapper>
        ))}
      </PoseGroup>
    </S.ArtistsList>
  );
}

Artists.defaultProps = {
  selection: {}
};

Artists.propTypes = {
  addedArtists: PropTypes.array.isRequired,
  addedListIsOpen: PropTypes.bool,
  addArtist: PropTypes.func.isRequired,
  removeArtist: PropTypes.func.isRequired,
  filters: PropTypes.object,
  searchTerm: PropTypes.string,
  selection: PropTypes.object,
  featured: PropTypes.array.isRequired
};

export default connect(
  null,
  { addArtist, removeArtist }
)(Artists);
