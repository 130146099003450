import React, { useState } from 'react';
import jump from 'jump.js';
import cc from 'classcat';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';

import { addArtist, removeArtist } from '../../actions/artists';
import TintedImage from '../TintedImage/TintedImage';
import SocialCount from '../SocialCount/SocialCount';
import AudioPlaylist from '../AudioPlayer/AudioPlaylist';

// utils
import { isProd } from '../../utils/environment';
import { getBackgroundUrl } from '../../utils/image';
import { isMobile } from '../../utils/browser';
import { parseHtmlEntities } from '../../utils/htmlEntities';

// styles
import * as S from './styles';
import { artistPageEffect } from '../../styles/effects';
import { BackgroundImage, ScrollButton } from '../../styles/shared-styles';

// icons
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as AddedIcon } from '../../assets/icons/tick.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as SoundcloudIcon } from '../../assets/icons/soundcloud.svg';

function ArtistOverview({
  artist = {},
  isAdded,
  addArtist,
  removeArtist,
  parallaxStyle,
  isPlayingAudio,
  onAudioPlayUpdate
}) {
  const [scrolledPast, setScrolledPast] = useState(false);

  const introData = artist.acf.page_intro || {};
  const songs = introData.songs ? introData.songs.filter((song) => song.file) : [];
  const hasAdditionalContent = !isEmpty(artist.acf.page_content);

  const renderSocialCounts = ({ counts = {}, accounts }) => {
    if (!accounts) {
      return null;
    }
    const validAccountTypes = Object.keys(accounts)
      .filter((accountType) => accounts[accountType] && accounts[accountType].length > 1)
      .slice(0, 3);
    let accs = {};
    validAccountTypes.forEach((type) => {
      accs[type] = accounts[type];
    });

    return (
      <S.SocialCounts>
        {accs.instagram && (
          <SocialCount
            className="social-count"
            username={accounts.instagram}
            type="instagram"
            count={counts.instagram}
          />
        )}
        {accs.facebook && (
          <a
            className="social-count"
            target="_blank"
            rel="noopener noreferrer"
            href={accounts.facebook}
          >
            <S.SocialIcon>
              <FacebookIcon />
            </S.SocialIcon>
          </a>
        )}
        {accs.youtube && (
          <SocialCount
            className="social-count"
            url={accounts.youtube}
            type="youtube"
            count={counts.youtube}
          />
        )}
        {accs.soundcloud && (
          <a
            className="social-count"
            target="_blank"
            rel="noopener noreferrer"
            href={accounts.soundcloud}
          >
            <S.SocialIcon>
              <SoundcloudIcon />
            </S.SocialIcon>
          </a>
        )}
        {accs.twitter && (
          <SocialCount
            className="social-count"
            username={accounts.twitter}
            type="twitter"
            count={counts.twitter}
          />
        )}
      </S.SocialCounts>
    );
  };

  const toggleAddedStatus = (event) => {
    event && event.preventDefault();
    if (isAdded) {
      removeArtist(artist.id);
    } else {
      addArtist(artist.id);
    }
  };

  const scrollToContent = (event) => {
    event.preventDefault();

    jump('#artistMainContent', {
      duration: 500,
      offset: -80
    });
  };

  const handleWaypointEnter = () => {
    setScrolledPast(false);
  };

  const handleWaypointLeave = () => {
    setScrolledPast(true);
  };

  return (
    <div>
      <S.Wrapper className={cc({ 'scrolled-past': scrolledPast })}>
        <TintedImage className="pinned artist-image" effects={artistPageEffect}>
          {<BackgroundImage url={getBackgroundUrl(introData.background_images)} />}
        </TintedImage>
        <S.Inner style={parallaxStyle}>
          {songs.length > 0 && (
            <AudioPlaylist
              songClassName="mobile-hidden"
              songs={songs}
              autoPlay={!isMobile() && isProd}
              isPlaying={isPlayingAudio}
              onPlayUpdate={onAudioPlayUpdate}
            />
          )}
          <S.AddedToggle
            className="mobile-hidden"
            onClick={toggleAddedStatus}
            icon={isAdded ? AddedIcon : AddIcon}
            value={isAdded ? 'added' : 'add to my list'}
          />
          <S.ArtistName className="color-grey">
            {parseHtmlEntities(artist.title.rendered)}
          </S.ArtistName>
          <S.TextContent>{introData.description}</S.TextContent>
          <S.Separator />
          {renderSocialCounts({
            counts: artist.social_counts,
            accounts: introData.social_accounts
          })}
        </S.Inner>
        {hasAdditionalContent && (
          <ScrollButton
            className="mobile-hidden scroll-btn"
            type="button"
            onClick={scrollToContent}
          >
            scroll
          </ScrollButton>
        )}
      </S.Wrapper>
      {hasAdditionalContent && (
        <Waypoint topOffset={-16} onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
      )}
      <S.Spacer />
    </div>
  );
}

const select = ({ artists }, ownProps) => ({
  isAdded: artists.added.indexOf(ownProps.artist.id) > -1
});

export default connect(
  select,
  { addArtist, removeArtist }
)(ArtistOverview);
