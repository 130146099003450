import styled from 'styled-components/macro';
import { gapStyle, FixedScreen } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';

export const RosterTeaser = styled(FixedScreen)`
  ${gapStyle.large}
  position: relative;
`;

export const RosterContent = styled('div')`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .teaser-main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 30px;

    .teaser-heading {
      margin: 2.5rem 0 1rem;
    }

    .teaser-content {
      margin-top: auto;
      text-align: left;
    }
  }

  .teaser-button {
    margin: 2rem 0 4rem;
  }

  @media (min-width: ${screens.lg}) {
    .teaser-main {
      position: absolute;
      align-items: flex-start;
      left: 0;
      transform: translate(-5rem, 0);
      padding: 1.5rem;
      background-color: ${({ theme }) => theme.colors.grey};
      bottom: 3rem;
      max-width: 450px;

      .teaser-heading {
        margin: 0 0 1.5rem;
      }

      .teaser-content {
        margin-top: 0;
        max-width: none;
      }
    }
    .teaser-button {
      position: absolute;
      top: 6rem;
      margin: 0;
      right: 0;
      transform: translate(40%, 0);
    }
  }
  @media (min-width: ${screens.xl}) {
    .teaser-main {
      transform: translate(-25%, 0);
      max-width: 600px;
    }
  }
`;
