import React, { useState } from 'react';
import ScrollPercentage from '../ScrollPercentage/ScrollPercentage';
import { getTransform, getOpacity } from './utils';
import { isMobile } from '../../utils/browser';

const defaultTransform = {
  offsets: {
    yMin: -20,
    yMax: 20
  },
  unit: '%'
};

function Parallax({
  children,
  transform = defaultTransform,
  opacity,
  disabled,
  start = 0,
  end = 100,
  threshold,
  activeOnMobile
}) {
  const [progress, setProgress] = useState(start);

  if (!activeOnMobile && isMobile()) {
    return typeof children === 'function' ? children({}) : children;
  }

  const handleUpdate = (value) => {
    const percentage = value * 100;
    if (percentage > start && percentage <= end) {
      setProgress(percentage);
    }
  };

  let style = {};

  if (transform) {
    style.transform = getTransform(progress, transform, start, end);
  }

  if (opacity) {
    style.opacity = getOpacity(progress, opacity, start, end);
  }

  return (
    <ScrollPercentage onChange={handleUpdate} threshold={threshold} disabled={disabled}>
      {typeof children === 'function' ? (
        children({ progress, style })
      ) : (
        <div className="parallax" style={style}>
          {children}
        </div>
      )}
    </ScrollPercentage>
  );
}

export default Parallax;
