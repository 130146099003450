import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';

export const Wrapper = styled('div')`
  position: relative;
  max-width: 500px;
  margin: 0 auto;

  .side-image {
    position: absolute;
    width: 100%;
  }

  .description-text {
    margin: 2rem auto 0;
  }

  .main-image {
    padding-top: 70px;
  }

  &.text-right {
    .side-image {
      top: 0;
      left: 0;
    }
    .description-text {
      top: 0;
      right: 0;
    }
    .main-image {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &.text-left {
    .side-image {
      top: 0;
      right: 0;
    }
    .description-text {
      top: 0;
      left: 0;
    }
    .main-image {
      margin-right: auto;
      margin-left: 0;
    }
  }

  @media (min-width: ${screens.md}) {
    max-width: none;

    .description-text {
      margin: 0;
      position: absolute;
    }
    &.text-right,
    &.text-left {
      .main-image {
        padding-top: 150px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

export const NarrowWrapper = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 325px;

  &.side-image {
    width: 55%;
  }

  &.main-image {
    width: 75%;
  }

  @media (min-width: ${screens.md}) {
    &.side-image {
      max-width: 325px;
    }

    &.main-image {
      max-width: 440px;
    }
  }
`;

export const TextContent = styled('div')`
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.darkblue};
  text-decoration: none;

  @media (min-width: ${screens.md}) {
    padding: 1.75rem;
  }
`;

export const TextBody = styled('div')`
  font-size: 0.875rem;
  line-height: 1.75;

  @media (min-width: ${screens.md}) {
    &.font-size--normal {
      font-size: 1.125rem;
      line-height: 1.5;
    }
  }
`;

export const TextHeading = styled('h3')`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 0.75rem;
  padding-bottom: 0.25rem;
  border-bottom: 3px solid ${({ theme }) => theme.colors.darkblue};
`;
