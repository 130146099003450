import React from 'react';
import cc from 'classcat';
import * as S from './styles';
import { SlideY } from '../../styles/shared-styles';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg';

function PlayerButton({ onClick, className, playing }) {
  return (
    <S.Button type="button" onClick={onClick} className={className}>
      <S.Circle className="circle">
        <SlideY className={cc({ down: playing })}>
          <PlayIcon />
        </SlideY>
        <SlideY className={cc({ up: !playing })}>
          <PauseIcon />
        </SlideY>
      </S.Circle>
    </S.Button>
  );
}

export default PlayerButton;
