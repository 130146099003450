import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import * as S from './styles';

function CloseButton({ onRequestClose }) {
  return (
    <S.Button type="button" onClick={onRequestClose}>
      <CloseIcon />
    </S.Button>
  );
}

export default CloseButton;
