import React, { useState } from 'react';
import cc from 'classcat';
import * as S from './styles';

function ArtistsFilter({ onSelection, filters, selection, className, onOpenGroup }) {
  const [openGroup, setOpenGroup] = useState(null);

  const onGroupChange = (group) => {
    setOpenGroup(group);
    onOpenGroup(group);
  };

  const toggleGroup = (group) => {
    onGroupChange(openGroup === group ? null : group);
  };

  const toggleGroupItem = (termId) => {
    const newSelection =
      selection.termId !== termId && openGroup
        ? {
            group: openGroup,
            termId
          }
        : {};
    onSelection(newSelection);

    // Always close the open group after selection
    onGroupChange(null);
  };

  return (
    <S.Wrapper className={cc([{ 'has-open-children': openGroup !== false }, className])}>
      {Object.keys(filters).map((groupName) => (
        <S.Group
          key={groupName}
          className={cc({
            open: openGroup === groupName
          })}
        >
          <S.GroupName
            className={cc(['group-name', { active: !openGroup && selection.group === groupName }])}
            onClick={() => toggleGroup(groupName)}
          >
            by <span className="hyphen" /> {groupName}
          </S.GroupName>
          <S.ListWrapper
            key={groupName}
            withParent={false}
            pose={openGroup === groupName ? 'open' : 'closed'}
          >
            <S.Gradient className={cc({ visible: openGroup === groupName })} />
            <S.List>
              {filters[groupName].map((item) => (
                <S.ListItem
                  key={item.term_id}
                  className={cc({ active: selection.termId === item.term_id })}
                  onClick={() => toggleGroupItem(item.term_id)}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.name }} />
                  <S.GroupItemCount>{item.count}</S.GroupItemCount>
                </S.ListItem>
              ))}
            </S.List>
          </S.ListWrapper>
        </S.Group>
      ))}
    </S.Wrapper>
  );
}

ArtistsFilter.defaultProps = {
  filters: {},
  selection: {},
  onSelection: () => {},
  onOpenGroup: () => {}
};

export default ArtistsFilter;
