import React from 'react';
import TintedImage from '../TintedImage/TintedImage';
import { BackgroundImage } from '../../styles/shared-styles';
import { faceEffect } from '../../styles/effects';
import * as S from './styles';
import { ReactComponent as PhoneFrameIcon } from '../../assets/icons/phone-frame.svg';
import { ReactComponent as YesIcon } from '../../assets/icons/yes.svg';
import { ReactComponent as NoIcon } from '../../assets/icons/no.svg';
import { ReactComponent as SpotifyIcon } from '../../assets/icons/spotify.svg';

function FaceFrame({ data, className, onDismiss, onSelect }) {
  if (!data) return null;

  const spotifyTrack = data.spotify_track;

  return (
    <S.FrameContainer className={className}>
      <PhoneFrameIcon className="phone-frame" />
      <S.Wrapper>
        <TintedImage
          style={{ height: '100%', maxHeight: '50%' }}
          effects={faceEffect}
        >
          <BackgroundImage url={data.image.sizes.large || data.image.url} />
        </TintedImage>
        <S.Content>
          <S.Title
            dangerouslySetInnerHTML={{ __html: `${data.name}, ${data.age}` }}
          />
          <S.SmallText dangerouslySetInnerHTML={{ __html: data.position }} />
          <S.Text dangerouslySetInnerHTML={{ __html: data.text }} />
          {spotifyTrack && (
            <React.Fragment>
              <S.SmallText>
                <SpotifyIcon />
                <span>My Anthem</span>
              </S.SmallText>
              <S.SpotifyTrack
                href={spotifyTrack.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {spotifyTrack.imageUrl && (
                  <BackgroundImage
                    className="spotify-track--image"
                    url={spotifyTrack.imageUrl}
                  />
                )}
                <div className="spotify-track--info">
                  <S.SmallText>{spotifyTrack.name}</S.SmallText>
                  <S.SmallText className="font-weight--normal">
                    {spotifyTrack.artists}
                  </S.SmallText>
                </div>
              </S.SpotifyTrack>
            </React.Fragment>
          )}
          <S.ButtonsRow>
            <S.IconButton onClick={onDismiss}>
              <NoIcon />
            </S.IconButton>
            <S.IconButton onClick={() => onSelect(data)}>
              <YesIcon />
            </S.IconButton>
          </S.ButtonsRow>
        </S.Content>
      </S.Wrapper>
    </S.FrameContainer>
  );
}

export default FaceFrame;
