import styled from 'styled-components/macro'

export const Count = styled('div')`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;

  svg {
    height: 30px;
    width: 30px;
    margin-right: 0.5rem;
    path {
      fill: currentColor;
    }
  }
`
export const AccountUrl = styled('a')`
  display: block;
  text-decoration: none;
  color: inherit;
`