import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from '../../components/ContactForm/ContactForm';
import WordPressPage from '../../components/WordPressPage';
import TintedImage from '../../components/TintedImage/TintedImage';
import Page from '../../components/Page/Page';
import { sendContactEmail } from '../../api/api';

import { BackgroundImage, TextBox } from '../../styles/shared-styles';
import * as S from './styles';
import { contactPageEffect } from '../../styles/effects';
import BlutjungOfficeImg from '../../assets/images/blutjung-office.jpg';

const formFields = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'name',
    required: true,
    label: 'your name',
    isVisible: true
  },
  { name: 'email', type: 'email', placeholder: 'email', required: true, label: 'your email' },
  {
    name: 'message',
    component: 'textarea',
    placeholder: 'say something nice',
    required: true,
    label: 'your message'
  }
];

const initialValues = {
  name: '',
  email: '',
  message: ''
};

function Contact(props) {
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values, actions) => {
    const response = await sendContactEmail(values);
    actions.setSubmitting(false);
    setSuccess(response.success === true);
  };

  const renderContent = (data) => (
    <S.PageContainer className={props.className}>
      {props.isStandalone && data.title.rendered && (
        <Helmet>
          <title>{data.title.rendered}</title>
        </Helmet>
      )}
      <S.PageContent>
        <S.LeftColumn className="mobile-hidden">
          <TintedImage className="pinned background" effects={contactPageEffect}>
            <BackgroundImage url={BlutjungOfficeImg} />
          </TintedImage>
          <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
        </S.LeftColumn>
        <S.Column>
          <h2>get in touch</h2>
          {success ? (
            <TextBox>
              Thanks for getting in touch, we’ll make sure to answer as soon as possible!
            </TextBox>
          ) : (
            <ContactForm onSubmit={onSubmit} initialValues={initialValues} fields={formFields} />
          )}
        </S.Column>
      </S.PageContent>
    </S.PageContainer>
  );

  return (
    <WordPressPage slug="contact">
      {({ data }) => {
        const content = data ? renderContent(data) : null;

        return props.isStandalone ? (
          <Page renderContactPage={false} ready={data !== null && data !== undefined}>
            {content}
          </Page>
        ) : (
          content
        );
      }}
    </WordPressPage>
  );
}

export default Contact;
