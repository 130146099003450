import React from 'react';
import { Link } from '@reach/router';
import TintedImage from '../TintedImage/TintedImage';
import Parallax from '../Parallax/Parallax';
import CountUp from '../CountUp/CountUp';
import {
  Image,
  ContentBox,
  TextFloater,
  HugeHeading,
  TransformYMobile
} from '../../styles/shared-styles';
import * as S from './styles';

function RosterTeaser({
  image,
  position = 'left',
  textPosition = 'right',
  artistsNumber,
  inView,
  renderedGif
}) {
  return (
    <Link to="/artists">
      <ContentBox className="roster-teaser">
        <S.Container>
          {renderedGif}
          <S.Wrapper className={`align-${position}`}>
            <TintedImage borderSize={20} effects={[]} className="on-hover--hide on-hover--scale">
              <Image src={image} />
            </TintedImage>
            <TextFloater
              as="header"
              offsetY={-50}
              className={`position-bottom align-${textPosition} is-unselectable`}
            >
              <Parallax>
                <TransformYMobile>
                  <HugeHeading className="heading">
                    {inView && (
                      <CountUp
                        from={0}
                        to={parseInt(artistsNumber, 10)}
                        initialDelay={100}
                        delay={0}
                        speed={4000}
                      />
                    )}
                    <span className="heading--smaller"> artists</span>
                  </HugeHeading>
                </TransformYMobile>
              </Parallax>
            </TextFloater>
            <S.ButtonWrapper>
              <S.RosterLink>View the Roster</S.RosterLink>
            </S.ButtonWrapper>
          </S.Wrapper>
        </S.Container>
      </ContentBox>
    </Link>
  );
}

export default RosterTeaser;
