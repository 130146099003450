import PearlAnderssonImg from '../../assets/images/examples/pearl-andersson.jpg';
import PearlAndersson2Img from '../../assets/images/examples/pearl-andersson2.jpg';
import Floater2Img from '../../assets/images/examples/floater2.jpg';

export const blogPosts = [
  {
    image: {
      url: PearlAnderssonImg
    },
    title: 'blog entry',
    url: '#',
    content: `„Working at a commercial Audio agency is everything but stressfree. You get used to it. After some time you may actually even need it. I started out as a musician in my early 20, and never went out of the music business. It went in many different directions. Last year my experiences even inspired me to write a book….“`
  },
  {
    image: {
      url: PearlAndersson2Img
    },
    title: 'blog entry',
    url: '#',
    content: `„Working at a commercial Audio agency is everything but stressfree. You get used to it. After some time you may actually even need it. I started out as a musician in my early 20, and never went out of the music business. It went in many different directions. Last year my experiences even inspired me to write a book….“`
  },
  {
    image: {
      url: Floater2Img
    },
    title: 'blog entry',
    url: '#',
    content: `„Working at a commercial Audio agency is everything but stressfree. You get used to it. After some time you may actually even need it. I started out as a musician in my early 20, and never went out of the music business. It went in many different directions. Last year my experiences even inspired me to write a book….“`
  }
];
