import React from 'react';
import cc from 'classcat';
import * as S from './styles';

const radius = 9;

function ProgressCircle({
  className,
  color,
  circleSize = 20,
  progress = 0,
  isAnimated,
  animationDuration,
  isPaused
}) {
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <S.ProgressCircle className={className} width={circleSize} height={circleSize}>
      <svg height="100%" viewBox="0 0 20 20" width="100%">
        <S.Circle
          className={cc([{ 'is-animated': isAnimated, 'is-paused': isPaused }])}
          cx="50%"
          cy="50%"
          fill="none"
          strokeWidth="2"
          r={radius}
          stroke={color}
          strokeLinecap="round"
          circumference={circumference}
          offset={offset}
          animationDuration={animationDuration}
        />
      </svg>
    </S.ProgressCircle>
  );
}

export default ProgressCircle;
