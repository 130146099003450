import React from 'react';
import cc from 'classcat';
import Parallax from '../Parallax/Parallax';
import ConditionalWrap from '../../utils/ConditionalWrap';
import FlexibleLink from '../FlexibleLink';
import PostOverlay from '../PostOverlay/PostOverlay';
import PostMedia from './PostMedia';
import PostText from './PostText';

// styles
import { ContentBox, HugeHeading, TextFloater, TransformYMobile } from '../../styles/shared-styles';
import * as S from './styles';

const widthTypes = {
  narrow: 1 / 3,
  wide: 2 / 3,
  normal: 0.5
};

const parallaxEffect = {
  offsets: {
    yMin: -50,
    yMax: 50
  },
  unit: 'px'
};

const Post = ({
  media_content: media,
  text_content: textContent,
  title,
  link,
  badge,
  renderedGif
}) => {
  const textClassName = textContent.classname || 'position-bottom';
  const combinedHeaderContent = textClassName === 'position-center';
  const alignClassName = `align-${media.position}`;
  const titleAlignClassName =
    title.position && title.position !== 'default' ? `align-${title.position}` : alignClassName;
  const mainColWidth = widthTypes[media.width] || 0.5;
  const { expandable } = textContent;
  const titleOffsetY = title.offsetY || (title.text.length < 12 ? -25 : undefined);

  return (
    <ConditionalWrap
      when={link && link.url && !expandable}
      wrap={(children) => <FlexibleLink to={link.url}>{children}</FlexibleLink>}
    >
      <ContentBox>
        <PostOverlay
          active={expandable}
          media={media}
          badge={badge}
          title={title}
          link={link}
          textContent={textContent}
          titleOffsetY={titleOffsetY}
          alignClassName={alignClassName}
          titleAlignClassName={titleAlignClassName}
        >
          <S.PostRow
            className={cc([
              alignClassName,
              'has-hover-effects',
              `media--width-${media.width}`,
              `text--${textClassName}`
            ])}
          >
            <S.PostColumn className="post-col--main" width={[1, 1, 0.5, mainColWidth]}>
              {renderedGif}
              <S.PostMedia className={cc({ fullwidth: media.type === 'video_external' })}>
                <PostMedia media={media} badge={badge} />
                <TextFloater
                  className={cc([textClassName, titleAlignClassName, 'is-unselectable'])}
                  offsetY={titleOffsetY}
                >
                  <Parallax transform={parallaxEffect}>
                    <TransformYMobile>
                      <HugeHeading dangerouslySetInnerHTML={{ __html: title.text }} />
                    </TransformYMobile>
                    {combinedHeaderContent && <PostText text={textContent.text} link={link} />}
                  </Parallax>
                </TextFloater>
              </S.PostMedia>
            </S.PostColumn>
            {!combinedHeaderContent && (
              <S.PostColumn
                className={cc(['post-col--content'])}
                width={[1, 1, 0.5, 1 - mainColWidth]}
              >
                <Parallax transform={parallaxEffect}>
                  <PostText className="post-content small" text={textContent.text} link={link} />
                </Parallax>
              </S.PostColumn>
            )}
          </S.PostRow>
        </PostOverlay>
      </ContentBox>
    </ConditionalWrap>
  );
};

export default Post;
