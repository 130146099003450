import React, { useRef, useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import PlayerButton from '../PlayerButton/PlayerButton';

function AudioPlayer({ buttonClassName, onPlayUpdate, isPlaying, ...restProps }) {
  const [playing, setPlaying] = useState(false);
  const audioElRef = useRef();

  useEffect(() => {
    if (!isPlaying && playing) {
      const { audioEl } = audioElRef.current;
      audioEl.pause();
      setPlaying(false);
    }
  }, [isPlaying]);

  const togglePlayback = (event, forcePlaying) => {
    if (event) {
      event.preventDefault();
    }

    if (audioElRef.current && audioElRef.current.audioEl) {
      const { audioEl } = audioElRef.current;

      if (audioEl.paused || forcePlaying) {
        audioEl.play();
        updatePlaying(true);
      } else {
        audioEl.pause();
        updatePlaying(false);
      }
    }
  };

  const onSourceChange = () => {
    if (restProps.src && playing) {
      // Continue playing if given a valid source file and has been playing
      togglePlayback(undefined, true);
    } else {
      updatePlaying(false);
    }
  };

  const onStoppedPlayback = () => {
    updatePlaying(false);
  };

  const onPlay = () => {
    updatePlaying(true);
  };

  const updatePlaying = (nextPlaying) => {
    setPlaying(nextPlaying);
    onPlayUpdate && onPlayUpdate(nextPlaying);
  };

  return (
    <div className="audio-player">
      <ReactAudioPlayer
        style={{ display: 'none' }}
        ref={audioElRef}
        onPlay={onPlay}
        onEnded={onStoppedPlayback}
        onError={onStoppedPlayback}
        onAbort={onSourceChange}
        {...restProps}
      />
      <PlayerButton
        type="button"
        onClick={togglePlayback}
        className={buttonClassName}
        playing={playing}
      />
    </div>
  );
}

export default AudioPlayer;
