import styled from 'styled-components/macro';
import { PaddingWrapper, BaseButton } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';

export const MobileControlsWrapper = styled('div')`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: transform 300ms ease;
  z-index: 50;

  &.visible {
    transform: translate3d(0, 0%, 0);
  }

  &.hidden {
    transform: translate3d(0, 100%, 0);
  }

  &.scrollable {
    top: 0;
    overflow-y: auto;
    max-height: 100vh;
    z-index: 11;

    > .mobile-controls {
      min-height: 100%;
    }
  }
`;

export const PageWrapper = styled(PaddingWrapper)`
  min-height: 100vh;
`;

export const MobileControls = styled(PaddingWrapper)`
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  flex-direction: column-reverse;
`;

export const Toggles = styled('div')`
  /* position: absolute;
  top: 0;
  left: 0; */
  margin-right: auto;

  > button {
    margin-bottom: 1rem;
  }
`;

export const IntroductionBlock = styled('div')`
  text-align: center;
  margin-bottom: 1rem;
  
  p + p {
    margin-top: 0.75rem;
  }

  > div {
    max-width: 16rem;
    margin-left: auto;
  }

  @media (min-width: ${screens.md}) {
    text-align: right;
  }
`;

export const PageIntro = styled('div')`
  margin: 2rem 0 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${screens.md}) {
    flex-direction: row;
    align-items: flex-start;
    
    > .page-header, > div {
      width: 33.33%;
    }
  }
`;

export const ListButton = styled(BaseButton)`
  color: rgba(0, 35, 57, 0.6);
  border: 1px solid ${({ theme }) => theme.colors.darkblue};
  padding: 1px 7px 2px;
  text-decoration: none;
  margin-bottom: 0;
  font-weight: bold;
  line-height: 1.5;
`;

export const CopyListButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.red};
  text-decoration: underline;
  font-weight: bold;
  font-style: italic;
`;
