import styled, { keyframes } from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { BoxFixed, gapStyle } from '../../styles/shared-styles';

const loop = keyframes`
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(100%, 0, 0); }
`;

const loopReverse = keyframes`
  0% { transform: translate3d(-100%, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
`;

const defaultDuration = 40;

export const SectionHeader = styled(BoxFixed)`
  position: relative;
`;

export const ImagesList = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  > img {
    margin: 0 1.5rem;
    max-width: 75px;
    max-height: 75px;
    height: auto;
    /* Reset the image color to black, then convert to blutjung-darkblue */
    filter: brightness(0) invert(11%) sepia(75%) saturate(965%) hue-rotate(168deg) brightness(98%)
      contrast(103%);

    @media (min-width: ${screens.lg}) {
      margin: 0 2rem;
      max-width: 150px;
      max-height: 150px;
    }
  }
`;

export const Marquee = styled('div')`
  ${gapStyle.large_xlarge}
  overflow: hidden;
  position: relative;
  height: 75px;

  @media (min-width: ${screens.lg}) {
    height: 150px;
  }
`;

export const MarqueeContainer = styled('div')`
  position: absolute;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  min-width: 100%;
  animation: ${loop} ${(props) => props.duration || defaultDuration}s linear infinite;

  &.cloned {
    transform: translate3d(-100%, 0, 0);
    animation: ${loopReverse} ${(props) => props.duration || defaultDuration}s linear infinite;
  }

  &.static {
    animation: none;
  }
`;
