import React from 'react';
import cc from 'classcat';
import * as S from './styles';
import { GradientBorder } from '../../styles/shared-styles';
import { defaultEffect } from '../../styles/effects';

function TintedImage({
  children,
  style,
  className,
  effects = defaultEffect,
  borderSize,
  borderClassName
}) {
  return (
    <S.TintedImage className={className} style={style}>
      {borderSize > 0 && <GradientBorder size={borderSize} className={borderClassName} />}
      <div className="image-container">
        <div className="image-content">{children}</div>
        {effects && effects.map((effect, index) => (
          <S.Effect
            key={index}
            className={cc([effect.className, 'effect'])}
            gradient={effect.gradient}
            bgColor={effect.color}
            mode={effect.mode}
          />
        ))}
      </div>
    </S.TintedImage>
  );
}

export default TintedImage;
