import React from 'react';
import cc from 'classcat';
import * as S from './styles';
import TintedImage from '../TintedImage/TintedImage';
import Parallax from '../Parallax/Parallax';
import { PortraitImage } from '../../styles/shared-styles';
import { reddishEffect } from '../../styles/effects';
import { stripHtmlStyleAttribute } from '../../utils/common';

function TripleBlock({
  className,
  textContent,
  textClassName,
  mainImage,
  sideImage,
  textPosition,
  children
}) {
  return (
    <S.Wrapper className={cc([className, `text-${textPosition}`])}>
      <S.NarrowWrapper className="side-image">
        <PortraitImage url={sideImage} />
      </S.NarrowWrapper>
      <S.NarrowWrapper className="main-image">
        {children}
        <Parallax activeOnMobile={true}>
          <TintedImage effects={reddishEffect}>
            <PortraitImage url={mainImage} />
          </TintedImage>
        </Parallax>
      </S.NarrowWrapper>
      <S.NarrowWrapper className="description-text">
        <S.TextContent>
          <S.TextHeading dangerouslySetInnerHTML={{ __html: textContent.title }} />
          <S.TextBody
            className={textClassName}
            dangerouslySetInnerHTML={{ __html: stripHtmlStyleAttribute(textContent.text) }}
          />
        </S.TextContent>
      </S.NarrowWrapper>
    </S.Wrapper>
  );
}

export default TripleBlock;
