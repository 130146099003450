import React, { Fragment, useState } from 'react';
import { PoseGroup } from 'react-pose';
import ContactForm from '../ContactForm/ContactForm';
import ArtistFooter from '../ArtistFooter/ArtistFooter';
import TintedImage from '../TintedImage/TintedImage';
import CloseButton from '../CloseButton/CloseButton';
import { formFields, initialValues } from './config';
import { sendContactEmail } from '../../api/api';
import { BackgroundImage, Fade, NormalButton, TextBox } from '../../styles/shared-styles';
import * as S from './styles';

function ArtistContact({ isOpen, onRequestOpen, onRequestClose, contact, artistName }) {
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values, actions) => {
    const response = await sendContactEmail(values);
    actions.setSubmitting(false);
    setSuccess(response.success === true);
  };

  return (
    <ContactForm
      initialFieldName="name"
      fields={formFields}
      initialValues={{ ...initialValues, artist: artistName }}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Fragment>
          <ArtistFooter contact={contact}>
            <NormalButton
              type="button"
              onClick={isOpen ? props.formProps.handleSubmit : onRequestOpen}
              disabled={isOpen && (props.formPose !== 'visible' || props.formProps.isSubmitting)}
            >
              booking request
            </NormalButton>
          </ArtistFooter>
          <PoseGroup>
            {isOpen && (
              <Fade
                key="contactOverlay"
                duration={700}
                style={{ zIndex: 10, position: 'relative' }}
              >
                <S.OverlayWrapper>
                  <S.OverlayContent>
                    <S.ImageCol className="mobile-hidden">
                      <TintedImage className="pinned">
                        <BackgroundImage url={contact.image} />
                      </TintedImage>
                    </S.ImageCol>
                    <S.ContactCol>
                      {success ? (
                        <TextBox>
                          Thanks for getting in touch, we’ll make sure to answer as soon as
                          possible!
                        </TextBox>
                      ) : (
                        props.renderedForm
                      )}
                    </S.ContactCol>
                  </S.OverlayContent>
                  <CloseButton onRequestClose={onRequestClose} />
                </S.OverlayWrapper>
              </Fade>
            )}
          </PoseGroup>
        </Fragment>
      )}
    </ContactForm>
  );
}

export default ArtistContact;
