import { loadState, saveState } from '../utils/localStorage';
import { useEffect, useRef, useState } from 'react';

export const useToggleBodyClass = (value, [on, off]) => {
  useEffect(() => {
    document.body.classList.remove(value ? off : on);
    document.body.classList.add(value ? on : off);
  });
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useCookies = () => {
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    const cookiesAllowed = loadState('cookiesAccepted', null);
    setValue(cookiesAllowed === true);
  }, []);

  const accept = () => {
    setValue(true);
    saveState(true, 'cookiesAccepted', false);
  };

  return {
    accepted: value,
    accept
  };
};
