import React from 'react';
import { SmallButton } from '../styles/shared-styles';

function Toggle({ value, icon: Icon, ...restProps }) {
  return (
    <SmallButton type="button" {...restProps}>
      <Icon />
      <span>{value}</span>
    </SmallButton>
  );
}

export default Toggle;
