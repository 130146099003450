import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';

export const CarouselWrapper = styled('div')`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CarouselContainer = styled('div')`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const CarouselSlidesList = styled('div')`
  display: flex;
  transform: translateZ(0);
  transition: transform 0.75s ease-out;
  touch-action: manipulation;
  will-change: transform;

  > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  img {
    max-width: 100%;
  }
`;

export const CarouselArrow = styled('button')`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  height: 60px;
  width: 21px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 5;
  cursor: pointer;
  padding: 100px 25px;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 35px;
    height: 1px;
    background: #fff;
  }

  &.carousel-arrow--left {
    left: 15px;
    &::before,
    &::after {
      left: 15px;
      transform-origin: 0% 50%;
    }
    &::before {
      transform: translate(0, -1px) rotate(55deg);
    }
    &::after {
      transform: translate(0, -1px) rotate(-55deg);
    }
  }

  &.carousel-arrow--right {
    right: 15px;
    &::before,
    &::after {
      right: 15px;
      transform-origin: 100% 50%;
    }
    &::before {
      transform: translate(0, -1px) rotate(-55deg);
    }
    &::after {
      transform: translate(0, -1px) rotate(55deg);
    }
  }
`;

export const CarouselFooter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const CarouselNavDot = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid ${({ theme }) => theme.colors.darkblue};
  transition: background 200ms linear;
  pointer-events: none;

  @media (min-width: ${screens.lg}) {
    height: 17px;
    width: 17px;
  }

  &.carousel-dot--active {
    background-color: ${({ theme }) => theme.colors.darkblue};
  }
`;

export const CarouselNavButton = styled('button')`
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  height: 24px;
  width: 24px;

  @media (min-width: ${screens.lg}) {
    height: 34px;
    width: 34px;
  }

  .carousel-dot--progress {
    height: 100%;
    width: 100%;
  }
`;
