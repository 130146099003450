import styled from 'styled-components/macro';
import { BaseButton } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';

export const Button = styled(BaseButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 20px;

  @media (min-width: ${screens.md}) {
    padding: 40px;
    position: fixed;
  }
`;
