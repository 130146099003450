import React, { Component, Fragment } from 'react';
import { getFrontPage } from '../../api/api';
import { PaddingWrapper } from '../../styles/shared-styles';
import Page from '../../components/Page/Page';
import ScrollUp from '../../components/ScrollUp';
import Intro from '../../components/Intro/Intro';
import ContentBlocks from '../../components/ContentBlocks/ContentBlocks';
import * as S from './styles';
import { isProd } from '../../utils/environment';

class Home extends Component {
  state = {
    data: null
  };

  async componentDidMount() {
    try {
      const data = await getFrontPage();
      if (data.blocks) {
        this.setState({ data });
      }
    } catch (err) {
      console.log(err);
    }
  }

  onOpeningEnded = () => {
    // Don't show the opening again in the same tab once it was played through once
    this.props.navigate(this.props.location.pathname, {
      state: {
        skipOpening: true
      },
      replace: true
    });
  };

  render() {
    const { data } = this.state;
    const ready = data !== null;
    const locationState = this.props.location.state || {};
    const introData = ready && data.intro ? data.intro : {};

    return (
      <Fragment>
        {ready && (
          <Intro
            onOpeningEnded={this.onOpeningEnded}
            skipOpening={!isProd || locationState.skipOpening}
            shouldScrollDown={locationState.shouldScrollDown}
            videoUrl={introData.video}
            thumbnail={introData.video_thumbnail}
          />
        )}
        <Page ready={ready} renderContactPage={true} contactPageClassName="mt-0">
          <ScrollUp>
            {ready && (
              <PaddingWrapper id="newsfeed">
                <S.BlocksContainer>
                  <ContentBlocks items={data.blocks} gifs={data.gifs} />
                </S.BlocksContainer>
              </PaddingWrapper>
            )}
          </ScrollUp>
        </Page>
      </Fragment>
    );
  }
}

export default Home;
