import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { aspectRatio } from '../../styles/mixins';

export const VideoWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const VideoContainer = styled('div')`
  ${aspectRatio(56.25)}

  &.background-red {
    background-color: ${({ theme }) => theme.colors.red};
  }

  &.aspect-ratio--tall {
    ${aspectRatio(100)}

    @media (min-width: ${screens.lg}) {
      ${aspectRatio(65, true)}
    }
  }

  iframe,
  .video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const VideoPreview = styled('div')`
  cursor: pointer;
`;
