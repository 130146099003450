import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { PaddingWrapper, BaseButton } from '../../styles/shared-styles';

export const PageContainer = styled(PaddingWrapper)`
  margin-top: 30px;
`;

export const IntroContainer = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 50px auto 0;
  line-height: 1.16;

  @media (min-width: ${screens.md}) {
    margin-top: 120px;
  }
`;

export const IntroHeadline = styled.h2`
  color: ${({ theme }) => theme.colors.red};
  margin: 0 0 40px;
  font-size: 1.75rem;

  @media (min-width: ${screens.md}) {
    font-size: 2.25rem;
  }
`;

export const IntroText = styled('div')`
  font-size: 1.125rem;
  p {
    margin-bottom: 18px;
  }
`;

export const BlocksList = styled('div')`
  .block + .block {
    margin-top: 90px;
  }

  @media (min-width: ${screens.md}) {
    .block + .block {
      margin-top: 180px;
    }
  }
`;

export const TabList = styled.div`
  text-align: center;
  margin: 100px 0 80px;

  @media (min-width: ${screens.md}) {
    margin: 290px 0 100px;
  }
`;

export const Tab = styled(BaseButton)`
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1;
  letter-spacing: 0.2px;
  text-decoration: underline;

  & + & {
    margin-left: 25px;
  }

  &.active {
    color: ${({ theme }) => theme.colors.red};
  }

  @media (min-width: ${screens.md}) {
    font-size: 3rem;

    & + & {
      margin-left: 55px;
    }
  }
`;
