import React, { useState, useEffect } from 'react';
import { usePrevious } from '../../hooks/common';
import * as S from './styles';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

function ArtistsSearch({ onSearchChange, onFocus, onBlur, className, isActive }) {
  const [searchTerm, setSearchTerm] = useState('');
  const prevIsActive = usePrevious(isActive);

  // Reset the search term if search has been closed
  useEffect(
    () => {
      if (prevIsActive && !isActive) {
        setSearchTerm('');
        if (onSearchChange) onSearchChange('');
      }
    },
    [isActive]
  );

  const handleInputChange = (event) => {
    event.preventDefault();

    setSearchTerm(event.target.value);
    if (onSearchChange) onSearchChange(event.target.value);
  };

  return (
    <S.SearchField className={className}>
      <S.SearchInput
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type="text"
      />
      <S.SearchButton>
        <SearchIcon />
      </S.SearchButton>
      {!searchTerm && (
        <S.SearchPlaceholder className="placeholder">
          <span className="mobile-only">search</span>
          <span className="mobile-hidden">search by genre, vibe, or name</span>
        </S.SearchPlaceholder>
      )}
    </S.SearchField>
  );
}

export default ArtistsSearch;
