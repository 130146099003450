import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import Page from '../../components/Page/Page';
import ProjectItem from '../../components/ProjectItem/ProjectItem';
import { getPage, getProjects } from '../../api/api';
import {
  PageBoxHeadingWrapper,
  PageBoxHeading
} from '../../styles/shared-styles';
import * as S from './styles';
import SearchField from '../../components/SearchField';
import { parseHtmlEntities } from '../../utils/htmlEntities';

function Projects() {
  const [state, setState] = useState({ page: null, projects: [] });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [page, projects] = await Promise.all([
          getPage('projects'),
          getProjects()
        ]);

        setState({
          page,
          projects: projects.map((i) => ({
            ...i,
            parsedTitle: parseHtmlEntities(i.title.rendered)
          }))
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const onSearchTermChange = debounce((searchTerm) => {
    setSearchTerm(searchTerm);
  }, 200);

  const ready = state.page !== null && state.projects.length > 0;
  const pageTitle = ready ? state.page.title.rendered : undefined;
  const projects = state.projects.filter(
    (i) => i.parsedTitle.indexOf(searchTerm) > -1
  );

  return (
    <Page ready={ready} title={pageTitle}>
      {ready && (
        <S.PageContainer>
          <S.FixedWrapper>
            <PageBoxHeadingWrapper>
              <PageBoxHeading>{pageTitle.toLowerCase()}</PageBoxHeading>
              {state.page.acf && (
                <S.SmallText
                  dangerouslySetInnerHTML={{
                    __html: state.page.acf.intro_text
                  }}
                />
              )}
            </PageBoxHeadingWrapper>
            <SearchField
              onChange={onSearchTermChange}
              className="search-field"
            />
            <S.ProjectList>
              {projects.length > 0 ? (
                projects.map((i) => <ProjectItem key={i.id} data={i} />)
              ) : (
                <div className="no-projects">No projects found</div>
              )}
            </S.ProjectList>
          </S.FixedWrapper>
        </S.PageContainer>
      )}
    </Page>
  );
}

export default Projects;
