import React, { useState } from 'react';
import cc from 'classcat';
import TintedImage from '../TintedImage/TintedImage';
import { getEmbedUrl } from '../../utils/video';
import * as S from './styles';
import PlayerButton from '../PlayerButton/PlayerButton';
import { BackgroundImage, AbsCenter } from '../../styles/shared-styles';

function VideoPlayer({
  id,
  isActive,
  title,
  videoUrl,
  thumbnailUrl,
  className,
  borderSize,
  onPlayRequest,
  posterClassName,
  posterEffects,
  buttonClassName
}) {
  const [canPlay, setCanPlay] = useState(thumbnailUrl === undefined);

  if (!videoUrl) {
    return null;
  }

  const onThumbnailClick = () => {
    setCanPlay(true);
    onPlayRequest && onPlayRequest(id);
  };

  const embedUrl = getEmbedUrl(videoUrl);

  return (
    <S.VideoWrapper>
      <S.VideoContainer className={className}>
        {canPlay && (isActive === undefined || isActive) ? (
          <iframe
            title={title || videoUrl}
            width="640"
            height="480"
            frameBorder="0"
            allowFullScreen={true}
            src={embedUrl}
          />
        ) : (
          <S.VideoPreview onClick={onThumbnailClick}>
            <TintedImage
              borderSize={borderSize}
              className={cc(['pinned', posterClassName])}
              effects={posterEffects}
            >
              <BackgroundImage
                className="video-thumbnail pinned"
                url={thumbnailUrl}
              />
            </TintedImage>
            <AbsCenter>
              <PlayerButton className={buttonClassName} playing={false} />
            </AbsCenter>
          </S.VideoPreview>
        )}
      </S.VideoContainer>
    </S.VideoWrapper>
  );
}

export default VideoPlayer;
