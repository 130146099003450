import { getLinearGradient } from './mixins';

const greyColorEffect = {
  color: `var(--color-grey)`,
  mode: 'color'
};

export const defaultEffect = [
  greyColorEffect,
  {
    gradient: getLinearGradient(32),
    mode: 'screen'
  }
];

export const blogTeaserEffect = [
  greyColorEffect,
  {
    gradient: getLinearGradient(41),
    mode: 'screen'
  }
];

export const artistsListEffect = [
  {
    gradient: getLinearGradient(0),
    mode: 'color'
  }
];

export const reddishEffect = [
  {
    gradient: getLinearGradient(0),
    mode: 'color'
  }
];

export const projectEffect = [
  {
    gradient: getLinearGradient(208),
    mode: 'color'
  }
];

export const audioPostEffect = [
  {
    color: '#000',
    mode: 'color'
  },
  {
    gradient: getLinearGradient(43),
    mode: 'screen'
  }
];

export const rosterViewEffect = [
  {
    color: '#fff',
    mode: 'color'
  },
  {
    gradient: getLinearGradient(52),
    mode: 'screen'
  }
];

export const contactPageEffect = [
  {
    gradient: `linear-gradient(32deg, #ff1616, #263343)`,
    mode: 'color'
  }
];

export const artistPageEffect = [
  // mobile
  {
    color: '#fff',
    mode: 'color',
    className: 'mobile-only'
  },
  {
    gradient: getLinearGradient(26),
    mode: 'screen',
    className: 'mobile-only'
  },
  // desktop
  {
    gradient: getLinearGradient(264),
    mode: 'color',
    className: 'mobile-hidden'
  },
  {
    gradient: `radial-gradient(circle at 49% 50%, rgba(44, 72, 91, 0), #00243a)`,
    mode: 'overlay',
    className: 'mobile-hidden'
  }
];

export const faceEffect = [
  {
    gradient: `linear-gradient(180deg, #EF4043 0%, transparent 18.33%), linear-gradient(226deg, #EF4043 17.37%, #002339 100%)`,
    mode: 'color',
  }
]

export const matchEffect = [
  {
    gradient: `linear-gradient(226deg, #EF4043 17.37%, #002339 100%)`,
    mode: 'color',
    className: "rounded"
  }
]