import React from 'react';
import cc from 'classcat';
import * as S from './styles';

const PageTransitionContext = React.createContext({});
const PageTransitionProvider = PageTransitionContext.Provider;
export const PageTransitionConsumer = PageTransitionContext.Consumer;

class PageTransition extends React.PureComponent {
  state = {
    active: false
  };

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    const prevPathname = prevProps.location.pathname;

    if (
      prevPathname !== pathname &&
      pathname !== '/' &&
      !(prevPathname.indexOf('/artists') > -1 && pathname.indexOf('artists') > -1)
    ) {
      this.setState({ active: true }, () => {
        setTimeout(() => {
          this.setState({ active: false });
        }, S.animationDuration);
      });
    }
  }

  render() {
    return (
      <PageTransitionProvider value={this.state}>
        <S.Transition className={cc([{ active: this.state.active }])} />
        {this.props.children}
      </PageTransitionProvider>
    );
  }
}

export default PageTransition;
