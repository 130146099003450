import React from 'react';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import TintedImage from '../TintedImage/TintedImage';
import Parallax from '../Parallax/Parallax';
import {
  SmallHeading,
  BadgeAlign,
  HugeHeading,
  ContentBox,
  TextBox,
  BackgroundImage,
  AbsCenter,
  TransformYMobile
} from '../../styles/shared-styles';
import { audioPostEffect } from '../../styles/effects';
import * as S from './styles';

const AudioPost = ({ title, text_content, badge, media_content: media }) => {
  return (
    <ContentBox>
      <S.Wrapper>
        <TintedImage borderSize={30} className="pinned" effects={audioPostEffect}>
          {media.image && <BackgroundImage url={media.image} className="pinned" />}
        </TintedImage>
        {badge && (
          <BadgeAlign className="lg-align-left">
            <SmallHeading>{badge.title}</SmallHeading>
          </BadgeAlign>
        )}
        <S.AudioPostDescription className="position-center align-left">
          <Parallax>
            <TransformYMobile>
              <HugeHeading dangerouslySetInnerHTML={{ __html: title.text }} />
            </TransformYMobile>
            {text_content && (
              <TextBox
                className="audio-text"
                dangerouslySetInnerHTML={{ __html: text_content.text }}
              />
            )}
          </Parallax>
        </S.AudioPostDescription>
        {media.audio && (
          <AbsCenter>
            <AudioPlayer src={media.audio} />
          </AbsCenter>
        )}
      </S.Wrapper>
    </ContentBox>
  );
};

export default AudioPost;
