import styled from 'styled-components/macro';
import { centerX } from '../../styles/utils';
import { screens } from '../../styles/theme';

export const Header = styled('header')`
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: all;
  background-color: ${({ theme }) => theme.colors.grey};

  @media (min-width: ${screens.md}) {
    height: 40px;
  }
`;

export const LogoWrapper = styled('div')`
  ${centerX}
  padding: 7px;
  img {
    width: 54px;
    height: 17.5px;
  }

  @media (min-width: ${screens.md}) {
    padding: 9px;
    img {
      width: 74px;
      height: 24px;
    }
  }
`;
