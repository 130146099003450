import React from 'react';
import { Link } from '@reach/router';
import Header from '../Header/Header';
import * as S from './styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { menuItems } from '../Navbar/navRoutes';

function NavOverlay({ className, toggleMenu, isOpen }) {
  return (
    <S.OverlayContainer
      initialPose="hidden"
      pose={isOpen ? 'visible' : 'hidden'}
      className={className}
    >
      <Header />
      <S.OverlayContent>
        <S.MenuItemList>
          {menuItems.map((item, index) => (
            <S.MenuItem key={item.name} position={index}>
              <Link onClick={toggleMenu} to={item.to} state={item.state}>
                {item.name}
              </Link>
            </S.MenuItem>
          ))}
          <S.MenuIndicator className="menu-indicator" />
        </S.MenuItemList>
      </S.OverlayContent>
      {isOpen && (
        <S.MenuIcon className="sm-bottom" onClick={toggleMenu}>
          <CloseIcon />
        </S.MenuIcon>
      )}
    </S.OverlayContainer>
  );
}

export default NavOverlay;
