import styled from 'styled-components/macro';
import { TextContent } from '../../styles/shared-styles';

export const Header = styled('header')`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Content = styled(TextContent)`
  background-image: linear-gradient(57deg,var(--color-red),var(--color-blue));
`;
