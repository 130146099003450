import React, { useState, useEffect, Fragment } from 'react';
import NavOverlay from './NavOverlay';
import { useToggleBodyClass, usePrevious } from '../../hooks/common';
import { ReactComponent as BurgerIcon } from '../../assets/icons/burger.svg';
import * as S from './styles';

function NavMenu({ pathname, onIsOpenChange }) {
  const [isOpen, setMenuIsOpen] = useState(false);
  const prevPathname = usePrevious(pathname);

  // Update body class according to the overlay state
  useToggleBodyClass(isOpen, ['overlay-open', 'overlay-closed']);

  useEffect(
    () => {
      // Close the menu on location.pathname change
      if (isOpen && pathname !== prevPathname) {
        setMenuIsOpen(false);
      }
    },
    [pathname]
  );

  const toggleMenu = () => {
    const nextIsOpen = !isOpen;
    setMenuIsOpen(nextIsOpen);
    onIsOpenChange && onIsOpenChange(nextIsOpen)
  };

  return (
    <Fragment>
      <NavOverlay toggleMenu={toggleMenu} isOpen={isOpen} />
      {!isOpen && (
        <S.MenuIcon onClick={toggleMenu}>
          <BurgerIcon />
        </S.MenuIcon>
      )}
    </Fragment>
  );
}

export default NavMenu;
