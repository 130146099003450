import React from 'react';
import TintedImage from '../TintedImage/TintedImage';
import { TextBox, SmallHeading, BadgeAlign, Box } from '../../styles/shared-styles';
import { blogTeaserEffect } from '../../styles/effects';
import * as S from './styles';

function BlogTeaser({ posts = [] }) {
  const postsSlice = posts.slice(0, 3);

  return (
    <S.BlogContainer>
      <BadgeAlign top={[-64, -64, -64, -128]} className="lg-align-right">
        <SmallHeading>blutjung edit</SmallHeading>
      </BadgeAlign>
      <S.PostsList>
        {postsSlice.map((post, key) => (
          <S.Post key={key}>
            {post.image && (
              <TintedImage borderSize={20} borderClassName="top-right" effects={blogTeaserEffect}>
                <S.PostThumbnail url={post.image.url} />
              </TintedImage>
            )}
            <header>
              <S.PostHeading>{post.title}</S.PostHeading>
            </header>
            <TextBox className="small">
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
              <Box as="p" mt={5}>
                <a href={post.url}>Read more ></a>
              </Box>
            </TextBox>
          </S.Post>
        ))}
      </S.PostsList>
    </S.BlogContainer>
  );
}

export default BlogTeaser;
