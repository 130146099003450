import React, { useState, Fragment } from 'react';
import cc from 'classcat';
import Overlay from '../Overlay/Overlay';
import PostMedia from '../Post/PostMedia';
import PostText from '../Post/PostText';
import FlexibleLink from '../FlexibleLink';
import ConditionalWrap from '../../utils/ConditionalWrap';
import { BoxFixed, TextFloater, TransformYMobile, HugeHeading } from '../../styles/shared-styles';
import * as P from '../Post/styles';
import * as S from './styles';

function PostOverlay({
  children,
  active,
  alignClassName,
  titleAlignClassName,
  titleOffsetY,
  media,
  badge,
  title,
  link,
  textContent
}) {
  const [open, setOpen] = useState(false);

  const openOverlay = () => {
    setOpen(true);
  };

  const closeOverlay = () => {
    setOpen(false);
  };

  if (!active) {
    return children;
  }

  return (
    <Fragment>
      <div style={{ cursor: 'pointer' }} onClick={openOverlay}>
        {children}
      </div>
      <Overlay open={open} onRequestClose={closeOverlay}>
        <BoxFixed>
          <S.Wrapper>
            <ConditionalWrap
              when={link && link.url}
              wrap={(children) => <FlexibleLink to={link.url}>{children}</FlexibleLink>}
            >
              <P.PostRow className={alignClassName}>
                <P.PostColumn className="post-col--main" width={[1, 1, 0.5]}>
                  <P.PostMedia className={cc({ fullwidth: media.type === 'video_external' })}>
                    <PostMedia media={media} badge={badge} />
                    <TextFloater
                      className={cc(['position-bottom', titleAlignClassName, 'is-unselectable'])}
                      offsetY={titleOffsetY}
                    >
                      <TransformYMobile>
                        <HugeHeading dangerouslySetInnerHTML={{ __html: title.text }} />
                      </TransformYMobile>
                    </TextFloater>
                  </P.PostMedia>
                </P.PostColumn>
                <P.PostColumn className={cc(['post-col--content'])} width={[1, 1, 0.5]}>
                  <PostText
                    className="post-content small"
                    text={textContent.expanded_text}
                    link={link}
                  />
                </P.PostColumn>
              </P.PostRow>
            </ConditionalWrap>
          </S.Wrapper>
        </BoxFixed>
      </Overlay>
    </Fragment>
  );
}

export default PostOverlay;
