import styled from 'styled-components/macro';
import { BackgroundImage, gapStyle } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';
import { getColoredBoxStyle } from '../../styles/mixins';

const colGutter = 32;

export const BlogContainer = styled('div')`
  ${gapStyle.large_xlarge}
  position: relative;
`;

export const PostsList = styled('section')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: ${screens.lg}) {
    flex-wrap: nowrap;
  }
`;

export const Post = styled('article')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;

  header {
    transform: translate(0, -50%);
  }

  &:not(:last-child) {
    margin-bottom: 8rem;
  }

  @media (min-width: ${screens.lg}) {
    flex-wrap: nowrap;
    text-align: left;

    &:not(:first-child) {
      padding-left: ${colGutter}px;
    }
    &:not(:last-child) {
      padding-right: ${colGutter}px;
      margin-bottom: 0;
    }
  }
`;

export const PostThumbnail = styled(BackgroundImage)`
  width: 215px;
  height: 215px;

  @media (min-width: ${screens.md}) {
    width: 230px;
    height: 230px;
  }
`;

export const PostHeading = styled('h2')`
  ${getColoredBoxStyle('red', 'darkblue').normal}
  margin: 0;
  line-height: 1.15;
  text-align: center;

  @media (min-width: ${screens.xl}) {
    ${getColoredBoxStyle('red', 'darkblue', true).large}
  }
`;
