import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { Box } from '../../styles/shared-styles';

const colGutter = 16;

export const PostRow = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &.has-hover-effects {
    &:hover {
      .on-hover--hide .effect {
        opacity: 0;
      }
      .on-hover--scale {
        transform: scale(0.95);
        .image-content {
          transform: scale(1.15);
        }
      }
    }
  }

  /* Position the trashy gifs using the main column instead of the row */
  &.media--width-wide .post-col--main {
    position: relative;
  }

  &.align-right {
    text-align: right;
  }

  @media (min-width: ${screens.md}) {
    align-items: center;
    flex-wrap: wrap;

    &.align-right {
      flex-direction: row-reverse;

      .post-content {
        margin-left: auto;
      }
    }

    &.align-left > div:not(:first-child),
    &.align-right > div:not(:last-child) {
      padding-left: ${colGutter}px;
    }

    &.align-left > div:not(:last-child),
    &.align-right > div:not(:first-child) {
      padding-right: ${colGutter}px;
    }
  }
`;

export const PostColumn = styled(Box)`
  &.post-col--main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: ${screens.md}) {
    &.post-col--content {
      padding-top: 2rem;
    }
    &.post-col--main {
      align-items: flex-start;
    }
  }
`;

export const PostMedia = styled('div')`
  position: relative;

  &.fullwidth {
    width: 100%;
  }

  @media (min-width: ${screens.md}) {
    .align-left & {
      margin-right: 20px;
    }
  }

  @media (min-width: ${screens.lg}) {
    .align-left & {
      margin-right: 30px;
    }
  }
`;
