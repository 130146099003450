let isMonitoring = false;
let isScrolling = false;
let watchers = new Set();

function onScroll(e) {
  if (!isScrolling) {
    isScrolling = true;
    requestAnimationFrame(update);
  }
}

function update() {
  isScrolling = false;
  watchers.forEach((cb) => cb());
}

function start() {
  if (!isMonitoring) {
    window.addEventListener('scroll', onScroll);
    isMonitoring = true;
  }
}

function stop() {
  if (isMonitoring) {
    watchers.clear();
    window.removeEventListener('scroll', onScroll);
    isMonitoring = false;
  }
}

export function watch(cb) {
  if (!isMonitoring) start();
  watchers.add(cb);
}

export function unwatch(cb) {
  watchers.delete(cb);
  if (!watchers.size) stop();
}

export function destroy() {
  stop();
}
