import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { TextContent, resetButtonStyle } from '../../styles/shared-styles';

export const Content = styled(TextContent)`
  background-color: ${({ theme }) => theme.colors.red};
`;

export const Header = styled('header')`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FormFieldCombined = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey};
  overflow: hidden;

  input {
    appearance: none;
    background-color: ${({ theme }) => theme.colors.red};
    border: 4px solid ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.grey};
    font-size: inherit;
    font-weight: bold;
    flex: 1 1 auto;
    width: 1%;
    outline: 0;
    padding: 0.45rem 1rem 0.5rem;

    ::placeholder {
      color: rgba(242, 242, 242, 0.6);
    }

    @media (min-width: ${screens.md}) {
      padding: 0.6rem 1rem 0.7rem;
    }
  }
  button {
    ${resetButtonStyle}
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.blue};
    font-size: inherit;
    font-style: italic;
    font-weight: bold;
    text-transform: lowercase;
    padding: 0.25rem 0.75rem 0.35rem;
    @media (min-width: ${screens.md}) {
      padding: 0.25rem 1.5rem 0.35rem;
    }
  }
`;

export const InfoMessage = styled('div')`
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.blue};
  font-size: inherit;
  font-style: italic;
  font-weight: bold;
  text-transform: lowercase;
  text-align: center;
  padding: 0.45rem 1rem 0.5rem;

  span {
    font-style: normal;
  }

  @media (min-width: ${screens.md}) {
    padding: 0.6rem 1rem 0.7rem;
  }
`;
