import styled from 'styled-components/macro';
import posed from 'react-pose';
import { screens } from '../../styles/theme';
import { aspectRatio } from '../../styles/mixins';
import { BaseButton } from '../../styles/shared-styles';

export const colGutter = {
  mobile: 10,
  desktop: 24
};

export const ArtistsList = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -${colGutter.mobile / 2}px -${colGutter.mobile}px;

  > div {
    width: 50%;
    background-clip: padding-box;
    border-left: ${colGutter.mobile / 2}px solid transparent;
    border-right: ${colGutter.mobile / 2}px solid transparent;
    border-bottom: ${colGutter.mobile}px solid transparent;
  }

  @media (min-width: ${screens.md}) {
    margin: 0 -${colGutter.desktop / 2}px -${colGutter.desktop}px;

    > div {
      width: 25%;
      border-left: ${colGutter.desktop / 2}px solid transparent;
      border-right: ${colGutter.desktop / 2}px solid transparent;
      border-bottom: ${colGutter.desktop}px solid transparent;
    }
  }
`;

export const ArtistWrapper = posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    transition: {
      duration: 500,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 300
    }
  },
  flip: {
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 150
    }
  }
});

export const ArtistImage = styled('div')`
  ${aspectRatio(120)}
`;

export const ArtistName = styled('h2')`
  margin: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue};
  line-height: 1.25;

  @media (min-width: ${screens.md}) {
    font-size: 1.5rem;
    font-style: normal;
    margin: 1rem 0;
  }
`;

export const ArtistButton = styled(BaseButton)`
  position: relative;
  overflow: hidden;
  width: 100%;

  svg {
    height: 18px;
    width: 18px;
    g,
    path {
      stroke: ${({ theme }) => theme.colors.blue};
    }
  }
  span {
    display: inline-block;
    margin-top: 0.5rem;
    opacity: 0.5;
    font-size: 0.5rem;
    font-weight: bold;
    text-align: center;
    color: rgba(44, 72, 91, 0.5);
  }

  @media (min-width: ${screens.md}) {
    height: 55px;
    span {
      font-size: 1.125rem;
      font-weight: normal;
      font-style: italic;
    }
    svg {
      height: 24px;
      width: 24px;
      opacity: 0.5;
    }
  }
`;

export const SlideBox = styled('div')`
  position: relative;
  overflow: hidden;
`;