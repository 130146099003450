export const menuItems = [
  {
    name: 'news',
    to: '/',
    state: {
      skipOpening: true,
      shouldScrollDown: true
    }
  },
  {
    name: 'about',
    to: '/about'
  },
  {
    name: 'projects',
    to: '/projects'
  },
  {
    name: 'artists',
    to: '/artists'
  },
  {
    name: 'contact',
    to: '/contact'
  }
];
