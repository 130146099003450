import { useState, useEffect } from 'react';
import { apiRoot } from '../api/api';

export default function useFetch(url, defaultValue = [], transformResponse) {
  const [data, setData] = useState(defaultValue);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async (url) => {
      try {
        const res = await fetch(apiRoot + url, {
          method: 'GET'
        });
        let json = await res.json();

        if (transformResponse && typeof transformResponse === 'function') {
          json = transformResponse(json);
        }

        setData(json);
      } catch (err) {
        setError(err);
      }

      setLoading(false);
    };

    fetchData(url);
  }, [url, transformResponse]);

  return [data, loading, error];
}

function getSingle(json) {
  return json && json.length > 0 ? json[0] : null;
}

export function useWordpressPage(slug) {
  return useFetch(`/wp/v2/pages?slug=${slug}`, null, getSingle);
}

export function useWordpressProject(slug) {
  return useFetch(`/wp/v2/project?slug=${slug}`, null, getSingle);
}
