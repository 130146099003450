import React from 'react';
import Parallax from '../Parallax/Parallax';
import * as S from './styles';

const parallaxTransform = {
  offsets: {
    yMin: 30,
    yMax: -30
  },
  unit: '%'
};

function TrashyImage({ image, style }) {
  return (
    <S.TrashyItem style={style}>
      <Parallax transform={parallaxTransform}>
        <img src={image} alt="" />
      </Parallax>
    </S.TrashyItem>
  );
}

export default TrashyImage;
