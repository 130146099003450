const checkDomain = (url) => {
  if (url.indexOf('//') === 0) {
    url = window.location.protocol + url;
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0];
};

export const isExternal = (url) => {
  return (
    (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
    checkDomain(window.location.href) !== checkDomain(url)
  );
};

export const getLocationOrigin = () => {
  return window.location.href.substring(0, window.location.href.lastIndexOf('/'));
};
