const DEFAULT_KEY = 'state';
const DATE_KEY = 'lastSaved';

const olderThanDays = (timestamp, days) => {
  return new Date().getTime() - timestamp > 1000 * 60 * 60 * 24 * days;
};

export const loadState = (stateKey = DEFAULT_KEY, maxAgeDays = 7) => {
  try {
    const serializedState = localStorage.getItem(stateKey);
    const json = serializedState === null ? undefined : JSON.parse(serializedState);
    if (json && maxAgeDays) {
      const { [DATE_KEY]: lastSaved, ...restState } = json;
      // If state is stale, remove it
      if (olderThanDays(lastSaved, maxAgeDays) || !lastSaved) {
        localStorage.removeItem(stateKey);
        return undefined;
      }
      return restState;
    }
    return json;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state, stateKey = DEFAULT_KEY, addTimestamp = true) => {
  try {
    const serializedState = addTimestamp
      ? JSON.stringify({ ...state, [DATE_KEY]: new Date().getTime() })
      : JSON.stringify(state);
    localStorage.setItem(stateKey, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
