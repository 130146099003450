import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import range from 'lodash/range';
import cc from 'classcat';
import * as S from './styles';
import ProgressCircle from '../ProgressCircle/ProgressCircle';

const SCROLL_THRESHOLD_TO_STOP_SCROLL = 30;

class Carousel extends Component {
  static get defaultProps() {
    return {
      infiniteLoop: true,
      selectedIndex: 0,
      showArrows: false,
      showNav: true,
      autoPlay: false,
      autoPlayInterval: 5000,
      pauseOnHover: true
    };
  }

  state = {
    dragStart: 0,
    dragStartTime: new Date(),
    index: 0,
    lastIndex: 0,
    transition: false,
    hasMouseEntered: false
  };

  componentWillMount() {
    const { selectedIndex } = this.props;

    this.setState({
      index: selectedIndex,
      lastIndex: selectedIndex
    });
  }

  componentDidMount() {
    if (!this.props.children) {
      return;
    }

    if (this.props.autoPlay) {
      this.startAutoPlay();
    }
  }

  componentWillUnmount() {
    this.clearAutoPlay();
  }

  componentWillReceiveProps({ selectedIndex, autoPlay }) {
    if (selectedIndex !== this.props.selectedIndex) {
      this.goToSlide(selectedIndex);
    }

    if (autoPlay !== this.props.autoPlay) {
      if (autoPlay) {
        this.startAutoPlay();
      } else {
        this.clearAutoPlay();
      }
    }
  }

  getDragX(event, isTouch) {
    return isTouch ? event.touches[0].pageX : event.pageX;
  }

  handleDragStart = (event, isTouch = true) => {
    this.setState({
      dragStart: this.getDragX(event, isTouch),
      dragStartTime: new Date(),
      transition: false,
      slideWidth: ReactDOM.findDOMNode(this.carouselWrapper).offsetWidth
    });
  };

  handleDragMove = (event, isTouch = true) => {
    const { dragStart, lastIndex, slideWidth } = this.state;
    const offset = dragStart - this.getDragX(event, isTouch);
    const percentageOffset = offset / slideWidth;
    const newIndex = lastIndex + percentageOffset;

    // Stop scrolling when scrolling above the the threshold
    if (Math.abs(offset) > SCROLL_THRESHOLD_TO_STOP_SCROLL) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.setState({ index: newIndex });
  };

  handleDragEnd = () => {
    const { slidesCount } = this.props;
    const { dragStartTime, index, lastIndex } = this.state;

    const timeElapsed = new Date().getTime() - dragStartTime.getTime();
    const offset = lastIndex - index;
    const velocity = Math.round((offset / timeElapsed) * 10000);

    let newIndex = Math.round(index);

    if (Math.abs(velocity) > 5) {
      newIndex = velocity < 0 ? lastIndex + 1 : lastIndex - 1;
    }

    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= slidesCount) {
      newIndex = slidesCount - 1;
    }

    this.setState({ dragStart: 0 });

    this.goToSlide(newIndex);
  };

  handleSlideChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.onControlsClick) {
      this.props.onControlsClick(event)
    }

    const newIndex = parseInt(event.target.value, 10);

    if (!isNaN(newIndex)) {
      this.goToSlide(newIndex);
    }
  };

  goToSlide(slideIndex) {
    const { slidesCount, infiniteLoop } = this.props;

    let index = slideIndex;

    if (index < 0) {
      index = infiniteLoop ? slidesCount - 1 : 0;
    } else if (index >= slidesCount) {
      index = infiniteLoop ? 0 : slidesCount - 1;
    }

    this.setState({
      index,
      lastIndex: index,
      transition: true
    });

    if (this.props.onChange) {
      this.props.onChange(index);
    }

    if (this.props.pauseOnHover) {
      // Reset the remaining time
      this.autoPlayRemainingTime = null;
      this.autoPlayPauseTime = null;
    }

    // Reset current timer and continue auto-playing
    if (this.props.autoPlay && !this.state.hasMouseEntered) {
      this.autoPlay();
    }
  }

  goToPrevSlide = () => {
    this.goToSlide(this.state.index - 1);
  };

  goToNextSlide = () => {
    this.goToSlide(this.state.index + 1);
  };

  pauseOnMouseEnter = () => {
    this.setState({ hasMouseEntered: true });
    this.autoPlayPauseTime = new Date().getTime();
    this.clearAutoPlay();
  };

  startOnMouseLeave = () => {
    this.setState({ hasMouseEntered: false });
    this.autoPlay();
  };

  clearAutoPlay() {
    if (!this.props.autoPlay) {
      return;
    }

    clearTimeout(this.autoPlayTimer);
  }

  startAutoPlay() {
    this.autoPlay();
    const carouselWrapperNode = this.carouselWrapper;

    if (this.props.pauseOnHover && carouselWrapperNode) {
      carouselWrapperNode.addEventListener('mouseenter', this.pauseOnMouseEnter);
      carouselWrapperNode.addEventListener('mouseleave', this.startOnMouseLeave);
    }
  }

  stopAutoPlay() {
    this.clearAutoPlay();
    const carouselWrapperNode = this.carouselWrapper;

    if (this.props.pauseOnHover && carouselWrapperNode) {
      carouselWrapperNode.removeEventListener('mouseenter', this.pauseOnMouseEnter);
      carouselWrapperNode.removeEventListener('mouseleave', this.startOnMouseLeave);
    }
  }

  autoPlay() {
    this.clearAutoPlay();

    let { autoPlayInterval } = this.props;

    if (this.props.pauseOnHover) {
      // If auto-play was paused, use the remaining time instead
      if (this.autoPlayRemainingTime) {
        autoPlayInterval = this.autoPlayRemainingTime;
      }

      // Subtract the time between pause and start from rest of the remaining autoPlay interval
      if (this.autoPlayStartTime && this.autoPlayPauseTime) {
        autoPlayInterval -= this.autoPlayPauseTime - this.autoPlayStartTime;
        this.autoPlayRemainingTime = autoPlayInterval;
        this.autoPlayPauseTime = null;
      }

      this.autoPlayStartTime = new Date().getTime();
    }

    this.autoPlayTimer = setTimeout(this.goToNextSlide, autoPlayInterval);
  }

  renderNavButton = (currentIndex) => {
    const { lastIndex } = this.state;
    const isActive = currentIndex === lastIndex;

    const buttonProps = {
      key: currentIndex,
      onClick: this.handleSlideChange,
      value: currentIndex
    };

    return (
      <S.CarouselNavButton {...buttonProps}>
        <S.CarouselNavDot className={cc({ 'carousel-dot--active': isActive })} />
        <ProgressCircle
          className="carousel-dot--progress"
          isAnimated={this.props.autoPlay && isActive}
          color={this.props.dotColor}
          progress={0}
          animationDuration={this.props.autoPlayInterval}
          isPaused={this.state.hasMouseEntered}
        />
      </S.CarouselNavButton>
    );
  };

  renderArrows() {
    const { slidesCount, infiniteLoop, arrowsClassName } = this.props;
    const { lastIndex } = this.state;

    return (
      <div className={cc(['carousel-arrows', arrowsClassName])}>
        {(infiniteLoop || lastIndex > 0) && (
          <S.CarouselArrow
            className="carousel-arrow carousel-arrow--left"
            onClick={this.handleSlideChange}
            value={lastIndex - 1}
          />
        )}
        {(infiniteLoop || lastIndex < slidesCount - 1) && (
          <S.CarouselArrow
            className="carousel-arrow carousel-arrow--right"
            onClick={this.handleSlideChange}
            value={lastIndex + 1}
          />
        )}
      </div>
    );
  }

  render() {
    const { children, showArrows, showNav, slidesCount } = this.props;
    const { index } = this.state;

    const slidesStyles = {
      width: `${100 * slidesCount}%`,
      transform: `translate3d(${-1 * index * (100 / slidesCount)}%, 0, 0)`
    };

    return (
      <S.CarouselWrapper
        className={cc(['carousel', this.props.className])}
        style={this.props.style}
        ref={(el) => {
          this.carouselWrapper = el;
        }}
      >
        <S.CarouselContainer className="carousel-container">
          {showArrows && slidesCount > 1 && this.renderArrows()}

          <S.CarouselSlidesList
            onTouchStart={this.handleDragStart}
            onTouchMove={this.handleDragMove}
            onTouchEnd={this.handleDragEnd}
            className="carousel-slides--list"
            style={slidesStyles}
          >
            {typeof children === 'function'
              ? children({ onNext: this.goToNextSlide, onPrev: this.goToPrevSlide })
              : children}
          </S.CarouselSlidesList>
        </S.CarouselContainer>
        {showNav && slidesCount > 1 && (
          <S.CarouselFooter className="carousel-footer">
            {range(0, slidesCount).map(this.renderNavButton)}
          </S.CarouselFooter>
        )}
      </S.CarouselWrapper>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  infiniteLoop: PropTypes.bool,
  selectedIndex: PropTypes.number,
  showArrows: PropTypes.bool,
  showNav: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPlayInterval: PropTypes.number,
  arrowsClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  slidesCount: PropTypes.number.isRequired,
  onControlsClick: PropTypes.func
};

export default Carousel;
