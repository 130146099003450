export const formFields = [
  {
    name: 'artist',
    type: 'text',
    placeholder: 'artist name',
    disabled: true,
    required: true,
    label: 'request to book',
    isVisible: true,
    inputClassName: 'bigger'
  },
  {
    name: 'name',
    type: 'text',
    placeholder: 'name',
    required: true,
    label: 'your name',
    isVisible: true
  },
  { name: 'email', type: 'email', placeholder: 'email', required: true, label: 'your email' },
  {
    name: 'message',
    component: 'textarea',
    placeholder: 'say something nice',
    required: true,
    label: 'your message'
  }
];

export const initialValues = {
  artist: '',
  name: '',
  email: '',
  message: ''
};
