import { css } from 'styled-components/macro';

export const pin = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const centerX = css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const centerY = css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`;

export const centerXY = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
