import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';

export const TrashyItem = styled('div')`
  position: absolute;
  max-width: 200px;
  max-height: 200px;
  z-index: -1;
  pointer-events: none;
  display: none;

  @media (min-width: ${screens.lg}) {
    display: block;
  }

  /* top right corner */
  .align-right & {
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
    transform: translate(50%, -50%);
  }

  /* bottom left corner */
  .align-left &,
  .newsletter-block &,
  .align-right.text--position-center &,
  .align-right.media--width-wide & {
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
    transform: translate(-50%, 50%);
  }

  /* bottom right corner */
  .align-left &,
  .roster-teaser & {
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
    transform: translate(50%, 50%);
  }
`;
