import React from 'react';
import * as S from './styles';

function ArtistFooter({ contact, children }) {
  return (
    <S.Wrapper>
      <S.Content>
        <S.InfoRow>
          {contact.name && (
            <S.InfoCol>
              <div>booking contact</div>
              <div>{contact.name}</div>
            </S.InfoCol>
          )}
          {contact.phone && (
            <S.InfoCol>
              <div>contact</div>
              <div>{contact.phone}</div>
            </S.InfoCol>
          )}
        </S.InfoRow>
        {children}
      </S.Content>
    </S.Wrapper>
  );
}

export default ArtistFooter;
