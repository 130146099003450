import styled, { css } from 'styled-components/macro';
import posed from 'react-pose';
import TextareaAutosize from 'react-textarea-autosize';
import { BaseInput, resetInputStyle, BaseButton } from '../../styles/shared-styles';
import { getColoredBoxStyle } from '../../styles/mixins';
import { screens } from '../../styles/theme';

const inputStyle = css`
  font-size: 1.5rem;
  font-weight: bold;
  color: inherit;
  width: 100%;
  display: block;

  ::placeholder {
    opacity: 0.6;
    font-style: italic;
    color: inherit;
  }

  @media (min-width: ${screens.md}) {
    font-size: 2rem;
  }

  @media (min-width: ${screens.lg}) {
    &.bigger {
      font-size: 3.5rem;
    }
  }

  @media (min-width: ${screens.xl}) {
    &.bigger {
      font-size: 4.5rem;
    }
  }
`;

export const Input = styled(BaseInput)`
  ${inputStyle}
  -webkit-text-fill-color: ${({ theme }) => theme.colors.darkblue};
`;

export const Textarea = styled(TextareaAutosize)`
  ${resetInputStyle}
  ${inputStyle}
`;

export const Label = styled('label')`
  font-size: 0.875rem;
  font-weight: bold;
  font-style: italic;
  display: block;
  margin-bottom: 0.25rem;

  @media (min-width: ${screens.md}) {
    font-size: 1.5rem;
  }
`;

export const FieldGroup = styled(
  posed.div({
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1000
      }
    },
    hidden: {
      opacity: 0,
      y: 50
    }
  })
)`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 1rem;
`;

export const SubmitButton = styled(BaseButton)`
  ${getColoredBoxStyle('darkblue', 'red').normal}
  margin-top: 0.75rem;
`;

export const Form = posed.form({
  visible: {
    staggerChildren: 100
  },
  hidden: {}
});
