import { orderBy } from '../utils/common';
import { isProd } from '../utils/environment';

export const apiRoot = isProd ? `//blutjung.agency/wp-json` : `//blutjung.localhost/wp-json`;

export const getFrontPage = () => {
  return fetch(`${apiRoot}/teegeme/v1/frontpage`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const getPage = (slug) => {
  return fetch(`${apiRoot}/wp/v2/pages?slug=${slug}`)
    .then((response) => response.json())
    .then((json) => {
      return json && json.length > 0 ? json[0] : null;
    });
};

export const getProject = (slug) => {
  return fetch(`${apiRoot}/wp/v2/project?slug=${slug}`)
    .then((response) => response.json())
    .then((json) => {
      return json && json.length > 0 ? json[0] : {};
    });
};

export const getProjects = () => {
  return fetch(`${apiRoot}/wp/v2/project?count=500`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const getArtist = (slug) => {
  return fetch(`${apiRoot}/wp/v2/artist?slug=${slug}`)
    .then((response) => response.json())
    .then((json) => {
      return json && json.length > 0 ? json[0] : {};
    });
};

export const getArtists = () => {
  return fetch(`${apiRoot}/wp/v2/artist?count=500`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const sendContactEmail = (fields) => {
  let formData = new FormData();

  for (let name in fields) {
    formData.append(name, fields[name]);
  }

  return fetch(`${apiRoot}/teegeme/v1/contact`, {
    method: "POST",
    body: formData
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const getArtistsPageSettings = () => {
  return getPage('artists').then((data) => (data ? data.acf : null));
};

export const getArtistFilters = () => {
  return fetch(`${apiRoot}/teegeme/v1/terms?term=genre,vibe`)
    .then((response) => response.json())
    .then((json) => {
      json.genre = orderBy(json.genre, 'count', true);
      json.vibe = orderBy(json.vibe, 'count', true);
      return json;
    });
};
