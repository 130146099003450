import styled, { css } from 'styled-components/macro';
import { PaddingWrapper } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';

const columnStyle = css`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 100%;
    flex-wrap: nowrap;

    @media (min-width: ${screens.md}) {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1rem;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    @media (min-width: ${screens.xl}) {
      &:not(:first-child) {
        margin-left: 100px;
      }
      &:not(:last-child) {
        margin-right: 100px;
      }
    }
  }
`;

export const OverlayWrapper = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: ${({ theme }) => theme.colors.grey};
  overflow-y: scroll;
`;

export const OverlayContent = styled(PaddingWrapper)`
  ${columnStyle}
  padding-bottom: 110px;

  @media (min-width: ${screens.md}) {
    padding-bottom: 95px;
  }
`;

export const ImageCol = styled('div')`
  height: calc(100vh - 140px);
  position: relative;
`;

export const ContactCol = styled('div')`
  margin-top: 3rem;
  padding: 0 30px;

  @media (min-width: ${screens.md}) {
    padding: 0;
    margin-top: 5rem;
  }
`;
