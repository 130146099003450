const breakpoints = ['576px', '768px', '992px', '1200px'];

export const colors = {
  grey: '#f2f2f2',
  blue: '#2c485b',
  darkblue: '#002339',
  red: '#ef4043'
};

const fontSizes = [12, 14, 16, 18, 20, 24, 30, 36, 48, 72];

const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 64, 80, 96, 128];

export default {
  breakpoints,
  fontSizes,
  space,
  colors
};

export const screens = {
  sm: breakpoints[0],
  md: breakpoints[1],
  lg: breakpoints[2],
  xl: breakpoints[3]
};