export const isEmail = (value) => /\S+@\S+\.\S+/.test(value) 

export const validateFields = (fields, values) => {
  let errors = {};

  fields.forEach((field) => {
    if (field.required && !values[field.name]) {
      errors[field.name] = 'This field is required';
    } else if (field.type === 'email' && !isEmail(values[field.name])) {
      errors[field.name] = 'Invalid email address'
    }
  });

  return errors;
};