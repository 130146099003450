import styled from 'styled-components/macro';
import posed from 'react-pose';
import { screens } from '../../styles/theme';
import { pin } from '../../styles/utils';

export const Wrapper = styled('div')`
  position: relative;
  margin: 0 0 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.darkblue};
  text-transform: lowercase;

  &.has-open-children {
    justify-content: space-between;
    flex-grow: 1;
  }

  @media (min-width: ${screens.md}) {
    height: auto;
    margin: 0 0 2rem;
    line-height: 1.25;
    flex-direction: row;

    &.has-open-children {
      justify-content: center;
    }
  }

  @media (min-width: ${screens.lg}) {
    font-size: 4.5rem;
  }
`;

export const Group = styled('div')`
  order: 1;

  &.open {
    order: 0;
  }

  @media (min-width: ${screens.md}) {
    margin-right: 2rem;

    &.open {
      order: 1;
    }
  }

  @media (min-width: ${screens.lg}) {
    margin-right: 3rem;
  }
`;

export const GroupName = styled('div')`
  cursor: pointer;
  text-align: center;
  width: 100%;
  user-select: none;

  .hyphen {
    border-top: 8px solid ${({ theme }) => theme.colors.darkblue};
    width: 0.5em;
    display: none;
    padding-bottom: 10px;
    transition: width 0.4s ease-out;
  }

  .open &,
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.red};
    span {
      border-color: ${({ theme }) => theme.colors.red};
      width: 1em;
    }
  }

  @media (min-width: ${screens.md}) {
    text-align: left;
  }

  @media (min-width: ${screens.lg}) {
    .hyphen {
      display: inline-block;
    }
  }
`;

export const ListWrapper = styled(
  posed.div({
    open: {
      opacity: 1,
      transition: {
        duration: 500,
        ease: 'easeOut'
      }
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 500,
        ease: 'easeOut'
      }
    }
  })
)`
  overflow: hidden;

  @media (min-width: ${screens.md}) {
    position: absolute;
    top: 4.25rem;
    left: 0;
    width: 100%;
    z-index: 10;

    .open & {
      padding-bottom: 50vh;
    }
  }

  @media (min-width: ${screens.lg}) {
    top: 7.25rem;
  }
`;

export const List = styled(
  posed.ul({
    open: {
      height: 'auto',
      y: 0,
      transition: {
        duration: 500,
        ease: 'easeOut'
      }
    },
    closed: {
      height: 0,
      y: 50,
      transition: {
        duration: 500,
        ease: 'easeOut'
      }
    }
  })
)`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled('li')`
  display: block;
  cursor: pointer;
  user-select: none;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.red};
  }

  @media (min-width: ${screens.md}) {
    display: inline-block;
    padding-right: 2rem;
    padding-bottom: 0.75rem;
  }

  @media (min-width: ${screens.lg}) {
    padding-right: 2.5rem;
    padding-bottom: 1rem;
  }
`;

export const ListItemName = styled('div')``;

export const Gradient = styled('div')`
  ${pin}
  pointer-events: none;
  z-index: -1;
  background-image: linear-gradient(to bottom, rgba(242, 242, 242, 1), rgba(255, 255, 255, 0));
  display: none;

  @media (min-width: ${screens.md}) {
    display: block;
    opacity: 0;
    transition: opacity 0.5s ease-out;

    &.visible {
      opacity: 1;
    }
  }
`;

export const GroupItemCount = styled('span')`
  display: none;
  font-size: 1rem;
  font-weight: bold;
  padding-left: 0.5rem;
  color: rgba(0, 35, 57, 0.6);

  @media (min-width: ${screens.md}) {
    display: inline;
  }

  @media (min-width: ${screens.lg}) {
    font-size: 1.5rem;
    padding-left: 0.75rem;
  }
`;
