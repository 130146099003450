import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { Link } from '@reach/router';
import TintedImage from '../TintedImage/TintedImage';
import { parseHtmlEntities } from '../../utils/htmlEntities';
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as AddedIcon } from '../../assets/icons/tick.svg';
import { BackgroundImage, SlideY } from '../../styles/shared-styles';
import { artistsListEffect } from '../../styles/effects';
import * as S from './styles';

const getImageUrl = (image = {}) => {
  if (image.sizes.medium) {
    return image.sizes.medium;
  } else if (image.sizes.large) {
    return image.sizes.large;
  }
  return image.url;
};

function ArtistListItem({ isAdded, artist, addArtist, removeArtist }) {
  const onAddClick = () => {
    addArtist(artist.id);
  };

  const onRemoveClick = () => {
    removeArtist(artist.id);
  };

  const data = artist.acf.general || {};
  const { artist_page_status: isPageActivated } = data;

  const artistImage = (
    <S.ArtistImage>
      <TintedImage effects={artistsListEffect} className="pinned on-hover--show on-hover--unzoom">
        <BackgroundImage url={getImageUrl(data.thumbnail)} />
      </TintedImage>
    </S.ArtistImage>
  );

  return (
    <Fragment>
      {isPageActivated ? <Link to={`/artists/${artist.slug}`}>{artistImage}</Link> : artistImage}
      <S.ArtistName>{parseHtmlEntities(artist.title.rendered)}</S.ArtistName>
      <S.ArtistButton type="button" onClick={isAdded ? onRemoveClick : onAddClick}>
        <S.SlideBox>
          <SlideY className={cc({ up: !isAdded })}>
            <AddedIcon />
          </SlideY>
          <SlideY className={cc({ down: isAdded })}>
            <AddIcon />
          </SlideY>
        </S.SlideBox>

        <S.SlideBox>
          <SlideY className={cc({ up: !isAdded })}>
            <span>added</span>
          </SlideY>
          <SlideY className={cc({ down: isAdded })}>
            <span>add to my list</span>
          </SlideY>
        </S.SlideBox>
      </S.ArtistButton>
    </Fragment>
  );
}

ArtistListItem.propTypes = {
  isAdded: PropTypes.bool,
  artist: PropTypes.object.isRequired,
  addArtist: PropTypes.func.isRequired,
  removeArtist: PropTypes.func.isRequired
};

export default ArtistListItem;
