export const ADD_ARTIST = 'artists/ADD_ARTIST';
export const REMOVE_ARTIST = 'artists/REMOVE_ARTIST';
export const SET_ADDED_ARTISTS = 'artists/SET_ADDED_ARTISTS';

export const addArtist = (artistId) => ({
  type: ADD_ARTIST,
  artistId
});

export const removeArtist = (artistId) => ({
  type: REMOVE_ARTIST,
  artistId
});

export const setAddedArtists = (added) => ({
  type: SET_ADDED_ARTISTS,
  added
});