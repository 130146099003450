import React from 'react';
import jump from 'jump.js';
import { Link, Location } from '@reach/router';
import * as S from './styles';
import { Image, BaseButton } from '../../styles/shared-styles';
import LogoImg from '../../assets/images/blutjung.png';

function Header() {
  const scrollToTop = () => {
    jump('#root', {
      duration: 750
    });
  };

  return (
    <Location>
      {({ location }) => (
        <S.Header id="header">
          <S.LogoWrapper>
            {location.pathname === '/' ? (
              <BaseButton type="button" onClick={scrollToTop}>
                <Image src={LogoImg} alt="blutjung" />
              </BaseButton>
            ) : (
              <Link
                to="/"
                state={{
                  skipOpening: true,
                  shouldScrollDown: true
                }}
              >
                <Image src={LogoImg} alt="blutjung" />
              </Link>
            )}
          </S.LogoWrapper>
        </S.Header>
      )}
    </Location>
  );
}

export default Header;
