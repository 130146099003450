import React, { Fragment } from 'react';
import shuffle from 'lodash/shuffle';
import Reveal from '../Reveal/Reveal';
import Post from '../Post/Post';
import ImageFloater from '../ImageFloater/ImageFloater';
import NewsletterBlock from '../NewsletterBlock/NewsletterBlock';
import PostsCarousel from '../PostsCarousel/PostsCarousel';
import AudioPost from '../AudioPost/AudioPost';
import Marquee from '../Marquee/Marquee';
import BlogTeaser from '../BlogTeaser/BlogTeaser';
import TextTeaser from '../TextTeaser/TextTeaser';
import RosterView from '../RosterView/RosterView';
import RosterTeaser from '../RosterTeaser/RosterTeaser';
import TrashyImage from '../TrashyImage/TrashyImage';
import { BoxFixed } from '../../styles/shared-styles';
import { isProd } from '../../utils/environment';
import * as D from './data';

const fullscreenLayouts = ['carousel', 'logos', 'fullscreen_teaser', 'break_line', 'spacer'];
const suitableGifBlocks = ['newsletter', 'media_text', 'roster_teaser'];

const getGifBlockPlacements = (items, gifs, maxCount) => {
  let blockIndices = items
    .map((item, index) => {
      if (suitableGifBlocks.indexOf(item.acf_fc_layout) > -1) {
        return index;
      }
      return -1;
    })
    .filter((i) => i > -1);

  blockIndices = shuffle(blockIndices).slice(0, maxCount);
  const shuffledGifs = shuffle(gifs).slice(0, blockIndices.length);

  let data = {};

  shuffledGifs.forEach((gif, index) => {
    data[blockIndices[index]] = gif;
  });

  return data;
};

const renderBlock = ({ acf_fc_layout, ...item }, gif) => {
  let renderedGif;

  if (gif) {
    renderedGif = <TrashyImage image={gif} />;
  }

  switch (acf_fc_layout) {
    case 'media_text':
      if (item.media_content.type === 'audio') {
        return <AudioPost {...item} />;
      }
      return <Post {...item} renderedGif={renderedGif} />;
    case 'image_floater':
      return <ImageFloater ml={item.offsetX} imageUrl={item.image} />;
    case 'carousel':
      return <PostsCarousel autoPlayInitial={isProd} items={item.slides} />;
    case 'logos':
      return <Marquee isAnimated={isProd} items={item.images} />;
    case 'text_floater':
      return <TextTeaser {...item} />;
    case 'fullscreen_teaser':
      return <RosterView {...item} />;
    case 'newsletter':
      return <NewsletterBlock title={item.title} text={item.text} renderedGif={renderedGif} />;
    case 'blog':
      return <BlogTeaser posts={D.blogPosts} />;
    case 'roster_teaser':
      return <RosterTeaser {...item} renderedGif={renderedGif} />;
    case 'spacer':
      return <div className="spacer" style={{ height: parseInt(item.height, 10) }} />;
    default:
      return null;
  }
};

function ContentBlocks({ items, gifs }) {
  let gifBlockPlacements = {};
  const maxGifCount = gifs ? parseInt(gifs.max_count, 10) : 0;

  if (gifs && gifs.images && maxGifCount > 0) {
    const availableGifs = gifs.images.map((i) => i.url);
    gifBlockPlacements = getGifBlockPlacements(items, availableGifs, maxGifCount);
  }

  return (
    <Fragment>
      {items.map((item, index) => {
        const gif = gifBlockPlacements[index];
        const renderedItem = renderBlock(item, gif);

        if (fullscreenLayouts.indexOf(item.acf_fc_layout) > -1) {
          return <Fragment key={index}>{renderedItem}</Fragment>;
        }
        
        return (
          <BoxFixed key={index}>
            <Reveal>{renderedItem}</Reveal>
          </BoxFixed>
        );
      })}
    </Fragment>
  );
}

export default ContentBlocks;
