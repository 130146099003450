import React from 'react';
import { PoseGroup } from 'react-pose';
import { NormalButton } from '../../styles/shared-styles';
import { useCookies } from '../../hooks/common';
import * as S from './styles';

function CookieOverlay() {
  const cookies = useCookies();

  return (
    <PoseGroup>
      {cookies.accepted === false && (
        <S.Wrapper key="CookieOverlay" className="cookie-overlay">
          <S.Content>
            <S.Heading>cookies</S.Heading>
            <S.TextContent>
              In order to use this website, cookies are a necessity.
            </S.TextContent>
            <NormalButton onClick={cookies.accept} type="button">
              I agree
            </NormalButton>
          </S.Content>
        </S.Wrapper>
      )}
    </PoseGroup>
  );
}

export default CookieOverlay;
