import styled from 'styled-components/macro';

export const animationDuration = 1000;

export const Transition = styled('div')`
  position: fixed;
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.red};
  transform: scaleY(0) translateZ(0);
  transform-origin: 0 100%;
  transition: transform ${animationDuration}ms cubic-bezier(0.42, 0.15, 0.06, 1.02);
  backface-visibility: hidden;

  &.active {
    transform: scaleY(1);
  }
`;
