import posed from 'react-pose';
import styled, { css } from 'styled-components/macro';
import {
  space,
  width,
  position,
  fontSize,
  color,
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  alignSelf,
  top,
  style
} from 'styled-system';
import { screens } from './theme';
import { getColoredBoxStyle, aspectRatio, getLinearGradient } from './mixins';
import { centerXY, pin } from './utils';

// Styles

export const basicTextStyle = css`
  font-weight: bold;
  font-size: 0.875rem;
`;

export const smallButtonTextStyle = css`
  line-height: 1.15;
  font-style: italic;
  font-weight: normal;
  font-size: 1.125rem;
  color: rgba(0, 35, 57, 0.5);
`;

export const resetInputStyle = css`
  appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
`;

export const resetButtonStyle = css`
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  appearance: none;
`;

export const gapStyle = {
  medium: css`
    margin-top: 4rem;
    margin-bottom: 4rem;

    @media (min-width: ${screens.lg}) {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }
  `,
  large: css`
    margin-top: 5rem;
    margin-bottom: 5rem;

    @media (min-width: ${screens.md}) {
      margin-top: 8rem;
      margin-bottom: 8rem;
    }

    @media (min-width: ${screens.lg}) {
      margin-top: 10rem;
      margin-bottom: 10rem;
    }
  `,
  large_xlarge: css`
    margin-top: 8rem;
    margin-bottom: 5rem;

    @media (min-width: ${screens.md}) {
      margin-top: 10rem;
      margin-bottom: 8rem;
    }

    @media (min-width: ${screens.lg}) {
      margin-top: 12rem;
      margin-bottom: 10rem;
    }
  `
};

export const bulletPointStyle = css`
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1.5em;

    li {
      position: relative;
    }
  }

  ul li:before,
  ul li:after {
    content: '';
    border-radius: 50%;
    height: 10px;
    width: 10px;
    position: absolute;
  }

  ul li:before {
    top: calc(50% - 7px);
    left: -1.4em;
    background-color: ${({ theme }) => theme.colors.darkblue};
  }

  ul li:after {
    top: calc(50% - 5px);
    left: -1.5em;
    background-color: #fff;
  }

  @media (min-width: ${screens.md}) {
    ul li:before,
    ul li:after {
      height: 14px;
      width: 14px;
    }
    ul li:before {
      top: calc(50% - 9px);
    }

    ul li:after {
      top: calc(50% - 7px);
    }
  }
`;

// Images

export const Image = styled('img')`
  max-width: 100%;
  height: auto;
  display: block;
`;

const backgroundImageProp = style({
  prop: 'url',
  cssProperty: 'backgroundImage',
  transformValue: (url) => (url ? `url(${url})` : 'none')
});

export const BackgroundImage = styled('div')`
  ${backgroundImageProp}
  height: 100%;
  width: 100%;
  background-size: ${(props) => props.backgroundSize || 'cover'};
  background-repeat: no-repeat;
  background-position: ${(props) => props.backgroundPosition || 'center'};

  &.pinned {
    ${pin}
  }
`;

export const PortraitImage = styled(BackgroundImage)`
  ${aspectRatio(133.5)}
  height: auto;
  width: auto;
`;

export const GradientBorder = styled('div')`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: ${getLinearGradient(185)};
  z-index: -1;
  right: -${(props) => props.size / 1.5}px;
  bottom: -${(props) => props.size / 1.5}px;
  display: none;

  &.mobile-visible {
    display: block;
  }

  &.top-right {
    right: -${(props) => props.size / 1.5}px;
    top: -${(props) => props.size / 1.5}px;
    bottom: auto;
  }

  &.same-sized {
    right: -${(props) => props.size}px;
    bottom: -${(props) => props.size}px;

    &.top-right {
      right: -${(props) => props.size}px;
      top: -${(props) => props.size}px;
      bottom: auto;
    }
  }

  @media (min-width: ${screens.md}) {
    display: block;
  }

  @media (min-width: ${screens.lg}) {
    right: -${(props) => props.size}px;
    bottom: -${(props) => props.size}px;

    &.top-right {
      right: -${(props) => props.size}px;
      top: -${(props) => props.size}px;
    }
  }
`;

// Layout

export const AbsCenter = styled('div')`
  ${centerXY}
`;

export const Box = styled('div')(
  {
    boxSizing: 'border-box'
  },
  space,
  width,
  fontSize,
  color,
  flex,
  alignSelf,
  position
);

export const Flex = styled(Box)(
  {
    display: 'flex'
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
);

export const ContentBox = styled(Box)`
  ${gapStyle.large}
`;

ContentBox.defaultProps = {
  as: 'article'
};

export const BoxFixed = styled('div')`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${screens.sm}) {
    width: 70%;
  }
`;

export const FixedScreen = styled('div')`
  ${aspectRatio(200)}
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${screens.sm}) {
    ${aspectRatio(90, true)}
  }

  @media (min-width: ${screens.md}) {
    ${aspectRatio(70, true)}
    width: 85%;
  }

  @media (min-width: ${screens.lg}) {
    width: 70%;
  }
`;

export const Pin = styled('div')`
  ${pin}
`;

export const PaddingWrapper = styled('div')`
  padding: 30px;

  @media (min-width: ${screens.md}) {
    padding: 40px;
  }
`;

export const NarrowContainer = styled('div')`
  max-width: 875px;
  margin: 5rem auto;
  width: 100%;

  &.my-0 {
    margin: 0 auto;
  }
`;

export const FluidContainer = styled('div')`
  margin: 0 auto;
  width: 100%;
  max-width: calc(100% - 50px);

  @media (min-width: ${screens.md}) {
    max-width: calc(100% - 100px);
  }

  @media (min-width: ${screens.lg}) {
    max-width: calc(100% - 180px);
  }
`;

export const TextFloater = styled('div')`
  text-align: center;

  &.is-unselectable {
    pointer-events: none;
  }

  @media (min-width: ${screens.md}) {
    position: absolute;
    text-align: inherit;

    &.position-bottom {
      bottom: 0;
      top: auto;
      transform: translate(0, ${(props) => props.offsetY || 45}%);

      &.align-left {
        left: -6rem;
        right: auto;
      }

      &.align-right {
        left: auto;
        right: -6rem;
      }
    }

    &.position-center {
      top: 50%;
      bottom: auto;
      width: 85%;

      &.align-left {
        right: 0;
        transform: translate(75%, -50%);
      }

      &.align-right {
        left: 0;
        transform: translate(-75%, -50%);
      }
    }
  }
`;

export const TextBox = styled('div')`
  ${basicTextStyle}
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;

  p + p {
    margin-top: 1rem;
  }

  @media (min-width: ${screens.md}) {
    margin-left: 0;
    margin-right: 0;
    font-size: 1.125rem;

    h2 + &,
    div + & {
      margin-top: 1rem;
    }
  }

  @media (min-width: ${screens.lg}) {
    font-size: 1.5rem;
  }

  &.fluid {
    max-width: 100%;
  }

  &.small {
    font-size: 0.875rem;
  }
`;

export const SmallColoredBox = styled(ContentBox)`
  position: relative;
  width: 100%;
  max-width: 416px;
  color: ${({ theme }) => theme.colors.grey};
  margin-left: auto;
  margin-right: auto;

  &.align-right {
    @media (min-width: ${screens.md}) {
      margin-right: 0;
    }
  }

  &.align-left {
    @media (min-width: ${screens.md}) {
      margin-left: 0;
    }
  }
`;

export const TextContent = styled('div')`
  ${basicTextStyle}
  ${bulletPointStyle}
  padding: 2rem 1rem 1rem;

  @media (min-width: ${screens.md}) {
    font-size: 1.25rem;
    padding: 3.5rem 1.8rem 1.8rem;
  }
`;

export const BadgeAlign = styled('div')`
  ${top}
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @media (min-width: ${screens.md}) {
    &.md-align-left {
      left: -2rem;
      transform: translate(0, -50%);
    }

    &.md-align-right {
      left: auto;
      right: -2rem;
      transform: translate(0, 50%);
    }
  }

  @media (min-width: ${screens.lg}) {
    &.lg-align-left {
      left: -2rem;
      transform: translate(0, -50%);
    }

    &.lg-align-right {
      left: auto;
      right: -2rem;
      transform: translate(0, 50%);
    }
  }
`;

BadgeAlign.defaultProps = {
  top: 0
};

export const TransformYMobile = styled('div')`
  transform: translate(0, -50%);
  position: relative;
  z-index: 1;
  @media (min-width: ${screens.md}) {
    transform: none;
  }
`;

// Buttons

export const BaseButton = styled('button')`
  ${resetButtonStyle}
`;

export const NormalButton = styled(BaseButton)`
  ${getColoredBoxStyle('darkblue', 'red').normal}
  transition-property: box-shadow, transform, opacity;
  opacity: 1;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const SmallButton = styled(BaseButton)`
  ${smallButtonTextStyle}
  display: flex;
  align-items: center;

  svg {
    width: 21px;
    height: auto;
  }

  svg + span {
    padding-left: 10px;
    padding-bottom: 3px;
  }
`;

export const ButtonLikeLink = styled('a')`
  display: inline-block;
  ${getColoredBoxStyle('darkblue', 'red').mediumLarge}
  
  @media (min-width: ${screens.lg}) {
    ${getColoredBoxStyle('darkblue', 'red', true).large}
  }
`;

export const ScrollButton = styled(BaseButton)`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  display: block;
  color: ${({ theme }) => theme.colors.grey};
  opacity: 1;
  transition: opacity 0.3s;

  &:hover:after {
    transform: translate3d(0, -10%, 0);
  }

  &:after {
    margin: 1rem auto 0;
    content: '';
    display: block;
    width: 2px;
    height: 50px;
    background-color: ${({ theme }) => theme.colors.grey};
    transform: translateZ(0);
    transition: transform 0.4s ease-out;

    @media (min-width: ${screens.lg}) {
      height: 101px;
    }
  }
`;

// Headings

export const SmallHeading = styled('h3')`
  margin: 0;
  line-height: 1.25;
  text-transform: lowercase;
  white-space: nowrap;
  ${getColoredBoxStyle('darkblue', 'red').small}

  @media (min-width: ${screens.md}) {
    box-shadow: 4px -4px 0 ${({ theme }) => theme.colors.red};
    font-size: 1.25rem;
  }

  @media (min-width: ${screens.lg}) {
    ${getColoredBoxStyle('darkblue', 'red', true).normal}
  }
`;

export const BaseBoxHeading = styled('h2')`
  ${getColoredBoxStyle('red', 'darkblue').normal}
  margin: 0;
  display: inline-block;
  line-height: 1.15;

  &.is-unselectable {
    pointer-events: none;
  }

  br {
    display: none;
  }

  @media (min-width: ${screens.md}) {
    font-style: normal;
    text-transform: lowercase;

    /* Reset the colored box styles */
    color: ${({ theme }) => theme.colors.red};
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    &:hover,
    &:hover:not(:disabled) {
      transform: none;
      box-shadow: none;
    }

    &.color-darkblue {
      color: ${({ theme }) => theme.colors.darkblue};
    }

    &.color-grey {
      color: ${({ theme }) => theme.colors.grey};
    }

    br {
      display: block;
      line-height: 0;
    }
  }
`;

export const BigHeading = styled(BaseBoxHeading)`
  @media (min-width: ${screens.md}) {
    font-size: 3.5rem;
  }

  @media (min-width: ${screens.xl}) {
    font-size: 4.5rem;
  }
`;

export const HugeHeading = styled(BaseBoxHeading)`
  @media (min-width: ${screens.md}) {
    font-size: 4rem;
  }

  @media (min-width: ${screens.lg}) {
    font-size: 6rem;
  }

  @media (min-width: ${screens.xl}) {
    font-size: 8rem;
  }
`;

export const BoxHeading = styled('h2')`
  ${getColoredBoxStyle('darkblue', 'red').normal}
  margin: 0;
  line-height: 1.15;
  white-space: nowrap;

  @media (min-width: ${screens.md}) {
    ${getColoredBoxStyle('darkblue', 'red', true).large}
  }
`;

// Misc

export const FakeLink = styled('span')`
  color: ${({ theme }) => theme.colors.red};
  text-decoration: underline;
`;

export const Separator = styled('div')`
  width: 129px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.darkblue};
  margin: 100px auto;

  @media (min-width: ${screens.md}) {
    margin: 123px auto;
    width: 275px;
    height: 3px;
  }

  @media (min-width: ${screens.lg}) {
    width: 375px;
  }
`;

export const BaseInput = styled('input')`
  ${resetInputStyle}
`;

export const StyledError = styled('div')`
  font-size: 0.875rem;
  font-weight: bold;
  font-style: italic;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red};

  &.color-grey {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

// Animations

export const Fade = posed.div({
  enter: {
    opacity: 1,
    transition: (props) => ({
      duration: props.duration,
      ease: 'easeOut'
    })
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 500,
      ease: 'easeOut'
    }
  },
  props: {
    duration: 500
  }
});

export const SlideY = styled('div')`
  transform: translateZ(0);
  transition: transform 0.3s ease-out;

  &.up,
  &.down {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.up {
    transform: translateY(-100%);
  }

  &.down {
    transform: translateY(100%);
  }
`;

export const PageBoxHeading = styled('h1')`
  ${getColoredBoxStyle('red', 'darkblue').mediumLarge}
  display: inline-block;
  margin: 0 0 1rem;
  line-height: 1.15;
  text-align: center;

  @media (min-width: ${screens.sm}) {
    ${getColoredBoxStyle('red', 'darkblue').large}
  }
`;

export const PageBoxHeadingWrapper = styled('header')`
  text-align: center;

  @media (min-width: ${screens.md}) {
    margin-top: 10px;
  }
`;
