import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { BaseButton } from '../../styles/shared-styles';

export const FrameContainer = styled.div`
  position: relative;
  display: inline-flex;

  .phone-frame {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    pointer-events: none;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 2.5%;
  left: 5%;
  right: 6%;
  bottom: 2.8%;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-end;
`;

export const Content = styled.div`
  letter-spacing: 0.05px;
  font-size: 1rem;
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${screens.sm}) {
    padding: 20px;
  }
`;

export const Title = styled.h3`
  font-weight: bold;
  font-style: italic;
  color: ${({ theme }) => theme.colors.red};
  margin: 0 0 12px;
  font-size: 1em;
`;

export const SmallText = styled.p`
  font-weight: bold;
  font-size: 0.75em;
  font-style: italic;

  svg {
    vertical-align: middle;
  }

  svg + span {
    margin-left: 8px;
  }

  &.font-weight--normal {
    font-weight: normal;
  }
`;

export const Text = styled.p`
  font-size: 0.75em;
  line-height: 1.45;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.darkblue};
    margin: 10px 0;
  }

  @media (min-width: ${screens.sm}) {
    line-height: 1.6;
  }
`;

export const IconButton = styled(BaseButton)`
  svg {
    height: 45px;
    width: 45px;
  }

  & + & {
    margin-left: 28px;
  }

  @media (min-width: ${screens.xl}) {
    svg {
      height: 64px;
      width: 64px;
    }
  }
`;

export const ButtonsRow = styled.div`
  text-align: center;
  margin-top: auto;
`;

export const SpotifyTrack = styled.a`
  display: flex;
  align-items: center;
  margin: 12px 0 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkblue};
  text-decoration: none;

  .spotify-track--image {
    width: 45px;
    height: 45px;
    margin-right: 12px;
    border-radius: 10px;
    flex-shrink: 0;
  }

  .spotify-track--info {
    line-height: 1.6;
  }
`;
