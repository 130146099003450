import React from 'react';
import { getPage } from '../api/api';

class Fetcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined,
      isLoading: false
    };
  }

  componentDidMount() {
    this.loadPage(this.props.slug);
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.slug !== prevProps.slug) {
      this.loadPage(this.props.slug);
    }
  }

  updateState(nextState) {
    if (this.willUnmount) return;
    this.setState(nextState);
  }

  loadPage(slug) {
    this.updateState({ isLoading: true });

    getPage(slug)
      .then((data) => {
        this.updateState({
          data,
          isLoading: false
        });
      })
      .catch(() => {
        this.updateState({ data: null, isLoading: false });
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children(this.state)}
      </React.Fragment>
    );
  }
}

export default Fetcher;
