import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

function qinticInOut(t) {
  if ((t *= 2) < 1) return 0.5 * t * t * t * t * t;
  return 0.5 * ((t -= 2) * t * t * t * t + 2);
}

class CountUp extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      counter: props.from
    };
  }

  componentDidMount() {
    this.start();

    setTimeout(this.start, this.props.initialDelay);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.to !== this.props.to || prevProps.from !== this.props.from) {
      this.start(this.props);
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  start = (props = this.props) => {
    this.clear();

    this.setState(
      {
        counter: props.from
      },
      () => {
        const { speed, delay } = this.props;
        const now = Date.now();
        this.endDate = now + speed;
        this.scheduleNextUpdate(now, delay);
        this.raf = requestAnimationFrame(this.next);
      }
    );
  };

  next = () => {
    const now = Date.now();
    const { speed, onComplete, delay } = this.props;

    if (now >= this.nextUpdate) {
      const progress = Math.max(0, Math.min(1, 1 - (this.endDate - now) / speed));
      this.updateCounter(progress);
      this.scheduleNextUpdate(now, delay);
    }

    if (now < this.endDate) {
      this.raf = requestAnimationFrame(this.next);
    } else if (onComplete) {
      onComplete();
    }
  };

  scheduleNextUpdate(now, delay) {
    this.nextUpdate = Math.min(now + delay, this.endDate);
  }

  updateCounter(progress) {
    const { from, to, easing } = this.props;
    const delta = to - from;
    const counter = from + delta * easing(progress);

    this.setState({ counter });
  }

  clear() {
    cancelAnimationFrame(this.raf);
  }

  render() {
    const { className, tagName: Tag, children: fn } = this.props;
    const value = this.state.counter.toFixed(0);

    if (fn) {
      return fn(value);
    }

    return <Tag className={className}>{value}</Tag>;
  }
}

CountUp.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number.isRequired,
  speed: PropTypes.number.isRequired,
  delay: PropTypes.number,
  onComplete: PropTypes.func,
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.func,
  easing: PropTypes.func,
  initialDelay: PropTypes.number
};

CountUp.defaultProps = {
  from: 0,
  delay: 100,
  tagName: 'span',
  easing: qinticInOut,
  initialDelay: 0
};

export default CountUp;
