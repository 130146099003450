import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FaceFrame from './FaceFrame/FaceFrame';
import Overlay from './Overlay/Overlay';
import TintedImage from './TintedImage/TintedImage';
import { matchEffect } from '../styles/effects';
import {
  PageBoxHeading,
  PageBoxHeadingWrapper,
  BackgroundImage
} from '../styles/shared-styles';

const CarouselWrapper = styled.div`
  margin: 50px auto;
  max-width: 1100px;
  width: 100%;
  position: relative;

  .react-multi-carousel-list {
    padding-bottom: 50px;
  }

  .carousel-dots {
    button {
      height: 17px;
      width: 17px;
      background: none;
      border-width: 3px;
      border-color: ${({ theme }) => theme.colors.darkblue};
      margin-right: 18px;
    }
    .react-multi-carousel-dot--active button {
      background: ${({ theme }) => theme.colors.darkblue};
    }
  }

  @media (min-width: 1024px) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 40%;
      z-index: 1;
      pointer-events: none;
    }
    &::before {
      left: 0;
      background: linear-gradient(
        90deg,
        #f2f2f2 0%,
        rgba(242, 242, 242, 0) 100%
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        -90deg,
        #f2f2f2 0%,
        rgba(242, 242, 242, 0) 100%
      );
    }
  }

  @media (min-width: 1600px) {
    max-width: 1200px;

    .react-multi-carousel-list {
      padding-bottom: 100px;
    }
  }
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    padding: 0 30px;
  }
`;

const MatchContainer = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MailLink = styled.a`
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 0.1px;
  text-decoration: underline;
  border-color: ${({ theme }) => theme.colors.red};
  margin-top: 30px;
  display: block;
  font-weight: bold;
  font-style: italic;
`;

const FaceImage = styled(BackgroundImage)`
  width: 195px;
  height: 195px;
  border-radius: 50%;
`;

export default ({ items }) => {
  const [open, setOpen] = useState(false);
  const [openSlide, setOpenSlide] = useState(null);
  const carouselRef = React.createRef();

  const carouselConfig = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: Math.min(3, items.length),
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const onNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const onMatch = data => {
    setOpen(true);
    setOpenSlide(data);
  };

  return (
    <React.Fragment>
      <CarouselWrapper>
        <Carousel
          ref={carouselRef}
          arrows={false}
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={carouselConfig}
          infinite={true}
          keyBoardControl={false}
          transitionDuration={500}
          dotListClass="carousel-dots"
        >
          {items.map((i, k) => (
            <SlideContainer key={k}>
              <FaceFrame
                onSelect={onMatch}
                onDismiss={onNextSlide}
                className="face-frame"
                data={i}
              />
            </SlideContainer>
          ))}
        </Carousel>
      </CarouselWrapper>
      <Overlay open={open} onRequestClose={() => setOpen(false)}>
        {openSlide && (
          <PageBoxHeadingWrapper>
            <PageBoxHeading>it's a match!</PageBoxHeading>
            <MatchContainer>
              <TintedImage effects={matchEffect}>
                <FaceImage
                  url={
                    openSlide.image_match
                      ? openSlide.image_match.sizes.large
                      : openSlide.image.sizes.large
                  }
                />
              </TintedImage>
              <MailLink href={`mailto:${openSlide.email}`}>
                Send a message
              </MailLink>
            </MatchContainer>
          </PageBoxHeadingWrapper>
        )}
      </Overlay>
    </React.Fragment>
  );
};
