import React from 'react';
import cc from 'classcat';
import * as S from './styles';

function Marquee({ items, isAnimated }) {
  if (!items || items.length === 0) {
    return null;
  }

  const content = (
    <S.ImagesList>
      {items.map((item, index) => (
        <img key={index} src={item.url} alt="" />
      ))}
    </S.ImagesList>
  );

  return (
    <S.Marquee>
      <S.MarqueeContainer className={cc({ static: !isAnimated })}>{content}</S.MarqueeContainer>
      <S.MarqueeContainer className={cc([{ static: !isAnimated }, 'cloned'])}>
        {content}
      </S.MarqueeContainer>
    </S.Marquee>
  );
}

export default Marquee;
