import React, { useRef } from 'react';
import posed from 'react-pose';
import { useInView } from 'react-intersection-observer';
import { isMobile } from '../../utils/browser';

const FadeUpwards = posed.div({
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: 1000,
      ease: 'easeOut'
    }
  },
  hidden: {
    opacity: 0,
    y: '30%',
    x: 0,
    transition: {
      duration: 700,
      ease: 'easeOut'
    }
  }
});

function Reveal({ children }) {
  const ref = useRef();
  const inView = useInView(ref, {
    threshold: 0.1,
    triggerOnce: true
  });

  // Dont't animate on mobile
  if (isMobile()) {
    return children;
  }

  return (
    <FadeUpwards
      withParent={false}
      initialPose="hidden"
      pose={inView ? 'visible' : 'hidden'}
      ref={ref}
    >
      {React.cloneElement((children), { inView })}
    </FadeUpwards>
  );
}

export default Reveal;
