import styled from 'styled-components/macro';
import { pin } from '../../styles/utils';
import { screens } from '../../styles/theme';

export const TintedImage = styled('div')`
  position: relative;
  /* Fix choppy scroll due to unnecessary redrawing in Firefox when using parallax */
  perspective: 1px;

  .image-content,
  .image-container {
    height: 100%;
  }

  /* Fix transition flickering bug in Safari */
  .image-container {
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  &.fluid {
    height: 100%;
    width: 100%;
  }

  &.pinned {
    ${pin}
  }

  &.on-hover--show {
    .effect {
      opacity: 0;
    }
    &:hover .effect {
      opacity: 1;
    }
  }

  &.on-hover--hide {
    .effect {
      opacity: 1;
    }
    &:hover .effect {
      opacity: 0;
    }
  }

  &.on-hover--unzoom {
    .image-container {
      overflow: hidden;
    }
    .image-content {
      transition: transform 0.5s ease-out;
      transform: translateZ(0) scale(1.1);
      backface-visibility: hidden;
    }
    &:hover .image-content {
      transform: translateZ(0) scale(1.01);
    }
  }

  &.on-hover--scale {
    transition: transform 0.5s ease-out;
    .image-container {
      overflow: hidden;
    }
    .image-content {
      transition: transform 0.5s ease-out;
      transform: translateZ(0);
      backface-visibility: hidden;
    }
    &:hover {
      transform: scale(0.95);
      .image-content {
        transform: scale(1.15);
      }
    }
  }

  /* Remove some effects in Safari due to inconsistent support */

  .safari &.on-hover--show .effect,
  .safari &.on-hover--hide .effect {
    display: none;
  }

  &.md-hidden {
    @media (min-width: ${screens.md}) {
      .effect {
        display: none;
      }
    }
  }

  &.lg-hidden {
    @media (min-width: ${screens.lg}) {
      .effect {
        display: none;
      }
    }
  }
`;

export const Effect = styled('div')`
  ${pin}
  background-image: ${(props) => props.gradient || 'none'};
  background-color: ${(props) => props.bgColor || 'transparent'};
  mix-blend-mode: ${(props) => props.mode};
  transition: all 0.5s ease-out;

  &.rounded {
    border-radius: 50%;
  }
`;
