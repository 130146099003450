export function scaleBetween(value, newMin, newMax, start, end) {
  return ((newMax - newMin) * (value - start)) / (end - start) + newMin;
}

function getTransformOffsets({ yMin, yMax, xMin, xMax }, percentage, start, end) {
  let x = 0;
  let y = 0;

  x = scaleBetween(percentage, xMax, xMin, start, end);
  y = scaleBetween(percentage, yMax, yMin, start, end);

  return {
    x: x || 0,
    y: y || 0
  };
}

export function getTransform(percentage, transform, start, end) {
  const values = getTransformOffsets(transform.offsets, percentage, start, end);
  return `translate3d(${values.x}${transform.unit}, ${values.y}${transform.unit}, 0)`;
}

export function getOpacity(percentage, opacity, start, end) {
  return scaleBetween(percentage, opacity.max, opacity.min, start, end);
}
