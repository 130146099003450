import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Router, Location } from '@reach/router';
import { ThemeProvider } from 'styled-components/macro';
import CookieOverlay from '../CookieOverlay/CookieOverlay';
import { isSafari } from '../../utils/browser';
import Navbar from '../Navbar/Navbar';
import PageTransition from '../PageTransition/PageTransition';

// pages
import HomePage from '../../pages/Home/Home';
import ArtistsPage from '../../pages/Artists/Artists';
import ArtistPage from '../../pages/Artist/Artist';
import ContactPage from '../../pages/Contact/Contact';
import AboutPage from '../../pages/About/About';
import ProjectPage from '../../pages/Project/Project';
import ProjectsPage from '../../pages/Projects/Projects';
import NotFound from '../../pages/NotFound';

// styles
import theme from '../../styles/theme';
import * as S from './styles';

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <S.App className={isSafari() ? 'safari' : undefined}>
          <Helmet defaultTitle="BLUTJUNG" titleTemplate="%s - BLUTJUNG" />
          <Location>
            {({ location }) => (
              <Fragment>
                <Navbar pathname={location.pathname} />
                <PageTransition location={location}>
                  <S.AppContent>
                    <Router>
                      <HomePage path="/" />
                      <ArtistsPage path="/artists" />
                      <ArtistPage path="/artists/:slug" />
                      <ContactPage isStandalone={true} path="/contact" />
                      <AboutPage path="/about" />
                      <ProjectsPage path="/projects" />
                      <ProjectPage path="/projects/:slug" />
                      <NotFound default />
                    </Router>
                  </S.AppContent>
                </PageTransition>
              </Fragment>
            )}
          </Location>
          <CookieOverlay />
        </S.App>
      </ThemeProvider>
    );
  }
}

export default App;
