import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';

export const Item = styled('div')`
  margin-top: 2.7rem;

  > div,
  > img {
    margin-left: auto;
  }

  img {
    /* 126 + 30 = 156 is the header + footer (mobile) */
    max-height: calc(100vh - 156px);
  }

  @media (min-width: ${screens.md}) {
    margin-top: 3.5rem;

    img {
      /* 92 + 40 = 132 is the header + footer (desktop) */
      max-height: calc(100vh - 132px);
    }
  }
`;

export const Wrapper = styled('section')`
  width: 100%;
  position: relative;
  padding-bottom: 130px;

  @media (min-width: ${screens.md}) {
    width: 75%;
    margin-left: auto;
    padding-bottom: 90px;
  }

  @media (min-width: ${screens.xl}) {
    width: calc(50% - 80px);
    margin-right: 80px;
    margin-bottom: 80px;
    padding-bottom: 0;
  }
`;
