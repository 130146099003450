import React from 'react';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube.svg';
import * as S from './styles';

const formatCount = (num) => {
  if (num > 999999) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num > 999) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num;
};

const getIconByType = (type) => {
  switch (type) {
    case 'twitter':
      return TwitterIcon;
    case 'instagram':
      return InstagramIcon;
    case 'youtube':
      return YoutubeIcon;
    default:
      return null;
  }
};

function SocialCount({ count, type = 'instagram', username, url, className }) {
  const Icon = getIconByType(type);
  if (!Icon) {
    console.error(`Invalid social account type: ${type}`);
    return null;
  }
  const accountUrl = username ? `https://${type}.com/${username}` : url;

  return (
    <S.AccountUrl className={className} target="_blank" rel="noopener noreferrer" href={accountUrl}>
      <S.Count>
        <Icon /> {count && formatCount(count)}
      </S.Count>
    </S.AccountUrl>
  );
}

export default SocialCount;
