import styled from 'styled-components/macro';
import posed from 'react-pose';
import { screens } from '../../styles/theme';
import { basicTextStyle } from '../../styles/shared-styles';

export const Wrapper = styled(
  posed.div({
    enter: {
      y: 0,
      delay: 1000,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: 1000
      }
    },
    exit: {
      y: '100%',
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: 500
      }
    }
  })
)`
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 15px 30px;

  @media (min-width: ${screens.md}) {
    padding: 25px 40px;
  }
`;

export const Content = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    min-width: 130px;
    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
  }
`;

export const Heading = styled('h2')`
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.15;
  color: ${({ theme }) => theme.colors.darkblue};
`;

export const TextContent = styled('p')`
  ${basicTextStyle}
  max-width: 24rem;
  text-align: center;
  margin: 1rem 0 1.5rem;
`;

export const SlideUp = posed.div({
  enter: {
    y: 0,
    delay: 300,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 5000
    }
  },
  exit: {
    y: '100%'
  }
});
