import styled from 'styled-components/macro';
import { BaseButton } from '../../styles/shared-styles';
import { screens, colors } from '../../styles/theme';
import { centerXY } from '../../styles/utils';

export const Button = styled(BaseButton)`
  display: inline-block;

  .circle,
  svg {
    transform: translateZ(0) scale(1);
    transform-origin: 50%;
    transition: transform 0.25s ease-out;
  }

  svg {
    transition-delay: 0.05s;
  }

  &.centered {
    ${centerXY}
  }

  .circle,
  svg {
    width: 47px;
    height: 47px;

    @media (min-width: ${screens.md}) {
      width: 67px;
      height: 67px;
    }

    @media (min-width: ${screens.lg}) {
      width: 87px;
      height: 87px;
    }
  }

  &:hover {
    .circle {
      transform: scale(1.1);
    }

    svg {
      transform: scale(1.15);
    }
  }

  &.circle-white .circle {
    background-color: #f2f2f2;
  }

  &.is-smaller .circle,
  svg {
    @media (min-width: ${screens.lg}) {
      width: 75px;
      height: 75px;
    }
  }
`;

export const Circle = styled('div')`
  border-radius: 50%;
  height: 87px;
  width: 87px;
  background-color: ${colors.darkblue};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const Slide = styled('div')`
  transform: translateZ(0);
  transition: transform 0.3s ease-out;

  &.up,
  &.down {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.up {
    transform: translateY(-100%);
  }

  &.down {
    transform: translateY(100%);
  }
`;
