import styled from 'styled-components/macro';
import posed from 'react-pose';

const AnimatedOverlay = posed.div({
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 300,
      ease: 'easeOut'
    },
    applyAtStart: { display: 'block' }
  },
  hidden: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 300
    },
    applyAtEnd: { display: 'none' }
  }
});

export const OverlayWrapper = styled(AnimatedOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(242, 242, 242, 0.9);
  overflow-x: hidden;
  display: none;
  overflow-y: scroll;
`;

export const OverlayContent = styled('div')`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
