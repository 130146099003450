import React, { useState } from 'react';
import Page from '../../components/Page/Page';
import TripleBlock from '../../components/TripleBlock/TripleBlock';
import FacesCarousel from '../../components/FacesCarousel';
import {
  NarrowContainer,
  PageBoxHeading,
  PageBoxHeadingWrapper
} from '../../styles/shared-styles';
import { stripHtmlStyleAttribute } from '../../utils/common';
import { useWordpressPage } from '../../hooks/useFetch';
import * as S from './styles';

const tabList = ['services', 'faces'];

function About() {
  const [activeTab, setActiveTab] = useState('faces');
  const [data, loading] = useWordpressPage('about');
  const ready = data !== null && data.acf !== undefined && !loading;
  const pageTitle = ready ? data.title.rendered : undefined;
  const intro = ready ? data.acf.intro : null;
  const faces = ready ? data.acf.faces : null;
  const facesEnabled = ready && data.acf.faces_active;

  return (
    <Page ready={ready} title={pageTitle}>
      {ready && (
        <S.PageContainer>
          <NarrowContainer className="my-0">
            <PageBoxHeadingWrapper>
              <PageBoxHeading>{pageTitle.toLowerCase()}</PageBoxHeading>
            </PageBoxHeadingWrapper>
            {intro && (
              <S.IntroContainer>
                <S.IntroHeadline
                  dangerouslySetInnerHTML={{
                    __html: stripHtmlStyleAttribute(intro.headline)
                  }}
                />
                <S.IntroText
                  dangerouslySetInnerHTML={{
                    __html: stripHtmlStyleAttribute(intro.text)
                  }}
                />
              </S.IntroContainer>
            )}
          </NarrowContainer>
          <S.TabList>
            {tabList.map(tabName => {
              if (tabName === 'faces' && !facesEnabled) {
                return null;
              }

              return (
                <S.Tab
                  key={tabName}
                  className={tabName === activeTab ? 'active' : undefined}
                  onClick={() => setActiveTab(tabName)}
                >
                  {tabName}
                </S.Tab>
              );
            })}
          </S.TabList>
          {activeTab === 'faces' && facesEnabled ? (
            faces && faces.length > 0 && <FacesCarousel items={faces} />
          ) : (
            <NarrowContainer className="my-0">
              <S.BlocksList>
                {data.acf.blocks &&
                  data.acf.blocks.map((block, index) => (
                    <TripleBlock
                      key={index}
                      className="block"
                      textPosition={block.layout}
                      textContent={block.text_content}
                      mainImage={block.main_image}
                      sideImage={block.side_image}
                    />
                  ))}
              </S.BlocksList>
            </NarrowContainer>
          )}
        </S.PageContainer>
      )}
    </Page>
  );
}

export default About;
