import React, { useState } from 'react';
import AudioPlayer from './AudioPlayer';
import * as S from './styles';
import { BaseButton } from '../../styles/shared-styles';

import { ReactComponent as PrevSongIcon } from '../../assets/icons/prev-song.svg';
import { ReactComponent as NextSongIcon } from '../../assets/icons/next-song.svg';

function AudioPlaylist({ autoPlay, songs, songClassName, onPlayUpdate, isPlaying }) {
  const [songId, setSongId] = useState(0);
  const song = songs[songId];

  const onNext = () => {
    onSongUpdate(songId + 1);
  };

  const onPrev = () => {
    onSongUpdate(songId - 1);
  };

  const onSongUpdate = (nextId) => {
    if (nextId > songs.length - 1) {
      setSongId(0);
    } else if (nextId < 0) {
      setSongId(songs.length - 1);
    } else {
      setSongId(nextId);
    }
  };

  if (!songs || songs.length === 0) {
    return null;
  }

  const hasManySongs = songs.length > 1;

  return (
    <S.PlaylistWrapper className={songClassName}>
      <S.Controls>
        {hasManySongs && (
          <BaseButton type="button" className="song-prev" onClick={onPrev}>
            <PrevSongIcon />
          </BaseButton>
        )}
        <AudioPlayer
          buttonClassName="song-toggle"
          autoPlay={autoPlay}
          src={song.file}
          isPlaying={isPlaying}
          onPlayUpdate={onPlayUpdate}
        />
        {hasManySongs && (
          <BaseButton type="button" className="song-next" onClick={onNext}>
            <NextSongIcon />
          </BaseButton>
        )}
      </S.Controls>
      <div className="song-description">
        <div className="song-title">{song.song_title}</div>
        <div className="album-title">{song.album_title}</div>
      </div>
    </S.PlaylistWrapper>
  );
}

export default AudioPlaylist;
