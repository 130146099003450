import React from 'react';
import TintedImage from '../TintedImage/TintedImage';
import { BackgroundImage } from '../../styles/shared-styles';
import * as S from './styles';

function ImageFloater({ imageUrl, ...restProps }) {
  return (
    <S.ImageFloater {...restProps}>
      <TintedImage className="fluid">
        <BackgroundImage url={imageUrl} />
      </TintedImage>
    </S.ImageFloater>
  );
}

export default ImageFloater;
