import React from 'react';
import throttle from 'lodash/throttle';
import { getBrowserScrollTop } from '../utils/scroll';

function atBottom(offset = 0) {
  return window.innerHeight + window.pageYOffset + offset >= document.body.offsetHeight;
}

class ScrollDirection extends React.Component {
  state = {
    direction: null
  };

  prevScrollTop = 0;

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = throttle(() => {
    const nextScrollTop = getBrowserScrollTop();

    if (Math.abs(nextScrollTop - this.prevScrollTop) >= this.props.threshold) {
      const direction = nextScrollTop > this.prevScrollTop || atBottom() ? 'bottom' : 'up';
      this.prevScrollTop = nextScrollTop;

      this.setState({ direction });
    }
  }, 300);

  render() {
    return React.cloneElement(
      this.props.children({
        direction: this.state.direction
      })
    );
  }
}

ScrollDirection.defaultProps = {
  children: () => {},
  threshold: 0
};

export default ScrollDirection;
