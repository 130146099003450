import React from 'react';
import Page from '../../components/Page/Page';
import ProjectItem from '../../components/ProjectItem/ProjectItem';
import { NarrowContainer, PaddingWrapper } from '../../styles/shared-styles';
import { useWordpressProject } from '../../hooks/useFetch';
import { parseHtmlEntities } from '../../utils/htmlEntities';
import NotFound from '../NotFound';

function Project({ slug }) {
  const [data, loading] = useWordpressProject(slug);
  const ready = data !== null && !loading;

  if (!loading && data === null) {
    return <NotFound />;
  }

  return (
    <Page
      ready={ready}
      title={data ? parseHtmlEntities(data.title.rendered) : undefined}
    >
      {ready && (
        <PaddingWrapper>
          <NarrowContainer className="my-0">
            <ProjectItem data={data} />
          </NarrowContainer>
        </PaddingWrapper>
      )}
    </Page>
  );
}

export default Project;
