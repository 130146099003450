import React, { useState } from 'react';
import cc from 'classcat';
import { Link } from '@reach/router';
import Header from '../Header/Header';
import { menuItems } from './navRoutes';
import NavMenu from '../NavMenu/NavMenu';
import * as S from './styles';

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => ({
      className: isCurrent ? cc([props.className, 'current']) : props.className
    })}
  />
);

const isPathChildOf = (pathname, parentName) => {
  return new RegExp(`^/${parentName}/.+$`).test(pathname);
};

function Navbar({ pathname }) {
  const [isOpen, setIsOpen] = useState(false);

  const getLinkProps = (item) => {
    let linkProps = {
      to: item.to,
      children: item.name,
      className: 'sweep-on-hover',
      state: item.state
    };

    if (item.to === '/artists' && isPathChildOf(pathname, 'artists')) {
      linkProps.children = 'back to ' + item.name;
      linkProps.className += ' current color-red';
    } else if (item.to === '/projects' && isPathChildOf(pathname, 'projects')) {
      linkProps.children = 'back to ' + item.name;
      linkProps.className += ' current color-red';
    }

    return linkProps;
  };

  return (
    <S.NavWrapper className={isOpen ? 'fullscreen' : undefined}>
      <Header />
      <div className="mobile-only">
        <NavMenu pathname={pathname} onIsOpenChange={setIsOpen} />
      </div>
      <S.NavInner className="mobile-hidden">
        {menuItems.map((item) => (
          <S.NavItem key={item.name}>
            <NavLink {...getLinkProps(item)} />
          </S.NavItem>
        ))}
      </S.NavInner>
    </S.NavWrapper>
  );
}

export default Navbar;
