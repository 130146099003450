import React, { useState } from 'react';
import { Link } from '@reach/router';
import cc from 'classcat';
import Parallax from '../Parallax/Parallax';
import Carousel from '../Carousel/Carousel';
import ConditionalWrap from '../../utils/ConditionalWrap';
import TintedImage from '../TintedImage/TintedImage';
import { SmallHeading, BadgeAlign, HugeHeading, BackgroundImage } from '../../styles/shared-styles';
import { colors } from '../../styles/theme';
import { stripHtmlStyleAttribute } from '../../utils/common';
import * as S from './styles';

function PostsCarousel({ items, autoPlayInitial }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(autoPlayInitial);

  const onSlideIndexUpdate = (index) => {
    setSlideIndex(index);
  };

  // Disable auto-play if the user has interacted with the carousel
  const onCarouselClick = () => {
    if (autoPlay) {
      setAutoPlay(false);
    }
  };

  const getLinkedArtistUrl = (slide) => {
    if (!slide.linkedArtist) {
      return null;
    }

    return `/artists/${slide.linkedArtist.post_name}`;
  };
  return (
    <S.CarouselWrapper onClick={onCarouselClick}>
      <Carousel
        autoPlay={autoPlay}
        autoPlayInterval={5000}
        pauseOnHover={false}
        onChange={onSlideIndexUpdate}
        showArrows={false}
        showNav={true}
        dotColor={colors.darkblue}
        slidesCount={items.length}
        onControlsClick={onCarouselClick}
      >
        {({ onPrev, onNext }) =>
          items.map((item, index) => {
            const linkedArtistUrl = getLinkedArtistUrl(item);

            return (
              <S.SlideOuter
                key={index}
                className={cc({ active: index === slideIndex })}
                onClick={() => {
                  if (index > slideIndex) {
                    return onNext();
                  } else if (index < slideIndex) {
                    return onPrev();
                  }
                }}
              >
                <ConditionalWrap
                  when={linkedArtistUrl != null}
                  wrap={(children) => <Link to={linkedArtistUrl}>{children}</Link>}
                >
                  <S.SlideContainer>
                    <TintedImage
                      borderSize={30}
                      className="fluid slide-background"
                      backgroundColor="#fff"
                    >
                      <BackgroundImage
                        url={item.image.url}
                        backgroundPosition={item.image.backgroundPosition}
                      />
                    </TintedImage>
                    <S.FadingWrapper className={slideIndex === index ? 'visible' : undefined}>
                      <BadgeAlign className="lg-align-left">
                        <SmallHeading>{item.badge}</SmallHeading>
                      </BadgeAlign>
                      <S.SlideContent>
                        <Parallax disabled={index !== slideIndex}>
                          <HugeHeading className="slide-heading color-red">
                            {item.title}
                          </HugeHeading>
                          <S.SlideDescription
                            dangerouslySetInnerHTML={{
                              __html: stripHtmlStyleAttribute(item.content)
                            }}
                          />
                        </Parallax>
                      </S.SlideContent>
                    </S.FadingWrapper>
                  </S.SlideContainer>
                </ConditionalWrap>
              </S.SlideOuter>
            );
          })
        }
      </Carousel>
    </S.CarouselWrapper>
  );
}

export default PostsCarousel;
