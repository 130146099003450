import styled from 'styled-components/macro';

export const App = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AppContent = styled.main`
  flex: 1 0 auto;
`;