import styled from 'styled-components/macro';
import { colors, screens } from '../../styles/theme';
import { PaddingWrapper } from '../../styles/shared-styles';

export const PageContainer = styled(PaddingWrapper)`
  position: relative;
  margin-top: 30px;

  .search-field {
    margin-top: 40px;
  }

  @media (min-width: ${screens.md}) {
    .search-field {
      margin: 0;
      position: absolute;
      top: 40px;
      left: 40px;
    }
  }
`;

export const FixedWrapper = styled.div`
  max-width: 940px;
  margin: 0 auto;
  width: 100%;
`;

export const SmallText = styled.p`
  font-size: 0.875rem;
  line-height: 1.15;
  text-align: center;
  letter-spacing: 0.033px;
  color: ${colors.darkblue};
  font-weight: bold;
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
`;

export const ProjectList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
  overflow: hidden;

  > div {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: ${screens.md}) {
    > div {
      width: calc(50% - 20px);
    }
  }

  > .no-projects:first-child:last-child {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.25rem;
    margin: 60px 0;
  }
`;
