export const getEmbedUrl = (url) => {
  const matchedUrl = url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );
  const type = matchedUrl[3];
  const videoId = matchedUrl[6];

  if (type.indexOf('youtu') > -1) {
    return `//www.youtube.com/embed/${videoId}?autoplay=1`;
  } else if (type.indexOf('vimeo') > -1) {
    return `//player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&autoplay=1`;
  }

  return null;
};
