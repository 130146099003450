import posed from 'react-pose';
import styled from 'styled-components/macro';
import { BaseButton } from '../../styles/shared-styles';
import { pin } from '../../styles/utils';
import { screens } from '../../styles/theme';

export const MenuItemList = styled('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  bottom: 4.375rem;
  transform: translate(-50%, 0);

  &:hover .menu-indicator {
    display: block;
    background-color: ${({ theme }) => theme.colors.red};
  }

  @media (min-width: ${screens.md}) {
    bottom: auto;
    top: 50%;
    left: 8.35rem;
    transform: translate(0, -50%);
  }
`;

export const MenuItem = styled(
  posed.div({
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        default: {
          duration: 800,
          delay: 50
        },
        y: {
          type: 'spring',
          stiffness: 200,
          damping: 100
        }
      }
    },
    hidden: {
      y: 35,
      opacity: 0,
      transition: {
        default: {
          duration: 800
        },
        y: {
          type: 'spring',
          stiffness: 200,
          damping: 100
        }
      }
    }
  })
)`
  font-size: 3rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkblue};
  line-height: 1.25;
  padding: 0.5rem 0;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover ~ .menu-indicator {
    top: ${(props) => props.position * 76 + 35}px;
  }

  @media (min-width: ${screens.md}) {
    font-size: 4.5rem;
    padding: 0 0 1rem;

    &:hover ~ .menu-indicator {
      top: ${(props) => props.position * 107 + 40}px;
    }
  }
`;

export const MenuIcon = styled(BaseButton)`
  display: block;
  position: absolute;
  z-index: 100;
  pointer-events: all;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  padding: 5px;

  &.sm-bottom {
    padding: 20px;
    transform: translate(-50%, 0);
    top: auto;
    bottom: 0;
    left: 50%;
  }

  svg {
    display: inline-block;
    height: 24px;
    width: 20px;
  }

  @media (min-width: ${screens.md}) {
    &,
    &.sm-bottom {
      top: 50%;
      bottom: auto;
      left: 0;
      padding: 10px;
      transform: translate(0, -50%);
    }
  }
`;

export const MenuIndicator = styled('div')`
  border-radius: 50%;
  position: absolute;
  top: 35px;
  left: -2rem;
  width: 18px;
  height: 18px;
  transition: top 0.3s ease-out;
  background-color: ${({ theme }) => theme.colors.grey};
  display: none;

  @media (min-width: ${screens.md}) {
    top: 61px;
    width: 24px;
    height: 24px;
    left: -2.5rem;
  }
`;

export const OverlayContainer = styled(
  posed.div({
    visible: {
      opacity: 1,
      delayChildren: 400,
      staggerChildren: 200,
      transition: {
        duration: 500,
        ease: 'easeOut'
      },
      applyAtStart: { display: 'block' }
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 1000,
        ease: 'easeOut'
      },
      applyAtEnd: { display: 'none' }
    }
  })
)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.grey};
  overflow: hidden;
  display: none;
`;

export const OverlayContent = styled('div')`
  ${pin}
  pointer-events: all;
`;
