import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { TextFloater } from '../../styles/shared-styles';

export const Wrapper = styled('div')`
  position: relative;
  width: 272px;
  height: 272px;
  margin: 0 auto 7rem;

  @media (min-width: ${screens.md}) {
    width: 350px;
    height: 350px;
    margin: 0;
  }

  @media (min-width: ${screens.lg}) {
    width: 500px;
    height: 500px;
  }
`;

export const AudioPostDescription = styled(TextFloater)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  transform: translate(0, 100%);
`;