import React from 'react';
import CloseButton from '../CloseButton/CloseButton'
import * as S from './styles';
import { useToggleBodyClass } from '../../hooks/common';

function Overlay({ open, withParent = false, className, children, onRequestClose }) {
  useToggleBodyClass(open, ['overlay-open', 'overlay-closed']);

  return (
    <S.OverlayWrapper
      className={className}
      withParent={withParent}
      pose={open ? 'visible' : 'hidden'}
    >
      {onRequestClose && <CloseButton onRequestClose={onRequestClose} />}
      <S.OverlayContent>{children}</S.OverlayContent>
    </S.OverlayWrapper>
  );
}

export default Overlay;
