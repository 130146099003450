import React from 'react';
import { TextBox, Box, FakeLink } from '../../styles/shared-styles';
import { stripHtmlStyleAttribute } from '../../utils/common';

function PostText({ className, link = {}, text }) {
  return (
    <TextBox className={className}>
      <div dangerouslySetInnerHTML={{ __html: stripHtmlStyleAttribute(text) }} />
      {link.title && (
        <Box as="p" mt={5}>
          <FakeLink className="post--fake-link">{link.title}</FakeLink>
        </Box>
      )}
    </TextBox>
  );
}

export default PostText;
