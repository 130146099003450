import React from 'react';
import { SmallColoredBox, BoxHeading } from '../../styles/shared-styles';
import * as S from './styles';

function TextTeaser({ title, content, position }) {
  return (
    <SmallColoredBox className={`align-${position}`}>
      <S.Header>
        <BoxHeading>{title}</BoxHeading>
      </S.Header>
      <S.Content>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </S.Content>
    </SmallColoredBox>
  );
}

export default TextTeaser;
