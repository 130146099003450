import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { BaseInput } from '../styles/shared-styles';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Input = styled(BaseInput)`
  margin-top: -3px;
  padding-left: 12px;
  font-size: 1.125rem;
  line-height: 1.16;
  letter-spacing: 0.257px;
  color: inherit;

  ::placeholder {
    opacity: 0.5;
    font-style: italic;
    color: inherit;
  }
`;

export default function SearchField({ onChange, placeholder = 'search', className }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    onChange && onChange(value);
  };

  return (
    <Label className={className}>
      <SearchIcon />
      <Input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
      />
    </Label>
  );
}
