import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { basicTextStyle, gapStyle } from '../../styles/shared-styles';

export const CarouselWrapper = styled('div')`
  ${gapStyle.medium}
  position: relative;

  .carousel-footer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    margin-bottom: 1.5rem;
  }

  @media (min-width: ${screens.md}) {
    .carousel-footer {
      position: static;
      transform: none;
      margin-bottom: 0;
    }
  }

  @media (min-width: ${screens.xl}) {
    .carousel-container {
      width: 80%;
    }
  }
`;

export const SlideOuter = styled('div')`
  padding-top: 2rem;

  &:not(.active) {
    cursor: pointer;
  }
`;

export const SlideContainer = styled('div')`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: calc(90vh - 80px);

  @media (min-width: ${screens.md}) {
    padding-bottom: 0.5rem;
    height: calc(91vh - 175px);
    width: 90%;
  }

  @media (min-width: ${screens.lg}) {
    padding-bottom: 1.25rem;
    /* 6.25rem is max. offset of the slide content */
    width: calc(100% - 12.5rem);
  }

  @media (min-width: ${screens.xl}) {
    width: calc(100% - 9rem);
  }
`;

export const SlideContent = styled('div')`
  position: absolute;
  bottom: 0;
  margin-bottom: 6rem;
  padding: 0 30px;
  color: ${({ theme }) => theme.colors.red};

  @media (min-width: ${screens.sm}) {
    right: 0;
    padding: 0 50px;
  }

  @media (min-width: ${screens.md}) {
    right: 0;

    .slide-heading {
      margin-left: -0.05em;
    }
  }

  @media (min-width: ${screens.lg}) {
    padding: 0;
    right: -6.25rem;
  }
`;

export const SlideDescription = styled('div')`
  ${basicTextStyle}
  margin-top: 1.5rem;
  max-width: 20rem;

  @media (min-width: ${screens.md}) {
    margin-top: 1rem;
  }
`;

export const FadingWrapper = styled('div')`
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
  &.visible {
    opacity: 1;
  }
`;
