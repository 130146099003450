import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import ContactPage from '../../pages/Contact/Contact';
import { PageTransitionConsumer } from '../PageTransition/PageTransition';
import * as S from './styles';

function Page({ ready = false, title, children, renderContactPage = true, contactPageClassName }) {
  return (
    <PageTransitionConsumer>
      {(transitionState) => {
        return (
          <S.Wrapper
            initialPose="hidden"
            pose={!transitionState.active && ready ? 'visible' : 'hidden'}
          >
            {title && (
              <Helmet>
                <title>{title}</title>
              </Helmet>
            )}
            {ready && (
              <Fragment>
                <div>{children}</div>
                {renderContactPage && <ContactPage className={contactPageClassName} />}
              </Fragment>
            )}
          </S.Wrapper>
        );
      }}
    </PageTransitionConsumer>
  );
}

export default Page;
