import styled from 'styled-components/macro';
import { BaseButton, BaseInput, smallButtonTextStyle } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';
import { centerY } from '../../styles/utils';

export const SearchField = styled('div')`
  position: relative;

  @media (min-width: ${screens.md}) {
    margin: 0 0 2rem;
  }
`;

export const SearchInput = styled(BaseInput)`
  border-bottom: 2px solid ${({ theme }) => theme.colors.darkblue};
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.15;
  color: ${({ theme }) => theme.colors.darkblue};
  padding: 0.5rem 0;
  width: 100%;

  &:focus ~ .placeholder {
    display: none;
  }

  @media (min-width: ${screens.md}) {
    line-height: 1.25;
    font-weight: bold;
    font-size: 2.25rem;
    border-bottom: 0;
    padding: 0;
  }

  @media (min-width: ${screens.lg}) {
    font-size: 4.5rem;
  }
`;

export const SearchButton = styled(BaseButton)`
  ${centerY}
  right: 0;

  @media (min-width: ${screens.md}) {
    display: none;
  }
`;

export const SearchPlaceholder = styled('div')`
  ${smallButtonTextStyle}
  ${centerY}
  right: 2rem;
  padding-bottom: 5px;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  @media (min-width: ${screens.md}) {
    left: 0;
    right: auto;
    font-weight: bold;
    font-size: 2.25rem;
    font-style: normal;
  }

  @media (min-width: ${screens.lg}) {
    font-size: 4.5rem;
  }
`;

export const SearchToggle = styled(BaseButton)`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  span {
    padding-left: 10px;
    padding-bottom: 3px;
  }
`;
