import posed from 'react-pose';

export const Wrapper = posed.div({
  visible: {
    opacity: 1,
    delay: 300,
    transition: { duration: 500, ease: 'easeOut' }
  },
  hidden: {
    opacity: 0,
    transition: { duration: 150 }
  }
});
