import React, { Fragment } from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import TintedImage from '../TintedImage/TintedImage';
import { SmallHeading, BadgeAlign, Image } from '../../styles/shared-styles';

function PostMedia({ media, badge }) {
  switch (media.type) {
    case 'image':
      return (
        <Fragment>
          <TintedImage borderSize={30} className="on-hover--hide on-hover--scale">
            <Image src={media.image} />
          </TintedImage>
          <BadgeAlign className="lg-align-left">
            <SmallHeading>{badge.title}</SmallHeading>
          </BadgeAlign>
        </Fragment>
      );
    case 'video_external':
      return (
        <VideoPlayer
          className="aspect-ratio--tall"
          title=""
          videoUrl={media.video_external_url.url}
          thumbnailUrl={media.image || media.video_external_url.thumbnail_url}
          borderSize={30}
          posterClassName="lg-hidden"
        />
      );
    default:
      return null;
  }
}

export default PostMedia;
