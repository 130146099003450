import React from 'react';
import { Image } from '../../styles/shared-styles';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import * as S from './styles';

function ArtistStory({ items, onVideoPlayRequest, activeVideoId }) {
  const renderItem = (item, index) => {
    switch (item.acf_fc_layout) {
      case 'image_single': {
        return <Image src={item.image} />;
      }
      case 'video_external': {
        const video = item.video_external_url || {};
        return (
          <VideoPlayer
            id={index}
            className="background-red"
            isActive={activeVideoId === index}
            onPlayRequest={onVideoPlayRequest}
            videoUrl={video.url}
            thumbnailUrl={video.thumbnail_url}
            posterClassName="lg-hidden"
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <S.Wrapper id="artistMainContent">
      {items.map((item, index) => (
        <S.Item key={index}>{renderItem(item, index)}</S.Item>
      ))}
    </S.Wrapper>
  );
}

export default ArtistStory;
