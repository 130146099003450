import styled, { keyframes } from 'styled-components/macro';

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const ProgressCircle = styled.svg`
  transform: rotate(-90deg);
  pointer-events: none;
`;

export const Circle = styled.circle`
  stroke-dasharray: ${(props) => props.circumference || 0};
  stroke-dashoffset: ${(props) => props.offset || 0};
  &.is-animated {
    animation: ${dash} ${(props) => props.animationDuration || 5000}ms linear forwards;
  }
  &.is-paused {
    animation-play-state: paused;
  }
`;
