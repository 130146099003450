import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';

export const Container = styled('div')`
  display: flex;
  margin: 0 auto;
  position: relative;

  img {
    max-height: 400px;
  }
`;

export const Wrapper = styled('div')`
  position: relative;
  margin: 0 auto;

  @media (min-width: ${screens.md}) {
    &.align-left {
      margin-left: 0;
    }

    &.align-right {
      margin-right: 0;
    }

    .heading {
      white-space: nowrap;
    }

    .heading--smaller {
      margin-left: 0.25rem;
      font-size: 2.25rem;
    }
  }
`;

export const ButtonWrapper = styled('div')`
  position: absolute;
  top: 2.75rem;
  left: 0;
  width: 100%;
  text-align: center;
`;

export const RosterLink = styled('span')`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.red};
  border: 4px solid ${({ theme }) => theme.colors.red};
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
  padding: 0.5rem 1rem;
  line-height: 1.25;
`;
