import { createStore } from 'redux';
import throttle from 'lodash/throttle';
import rootReducer from './reducers';
import { loadState, saveState } from './utils/localStorage';

// Load state from localStorage
let persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

let cookiesAllowed = loadState('cookiesAccepted', null);

store.subscribe(
  throttle(() => {
    // Check if cookies are allowed each time,
    // as the user might have agreed to them in the meantime
    if (!cookiesAllowed) {
      cookiesAllowed = loadState('cookiesAccepted', null);
    }
    if (cookiesAllowed) {
      // Sync parts of Redux state with localStorage if cookies are allowed
      const { artists } = store.getState();
      saveState({ artists });
    }
  }, 1000)
);

export default store;
