import * as A from '../actions/artists';

const initialState = {
  added: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case A.ADD_ARTIST:
      return {
        ...state,
        added: [...state.added, action.artistId]
      };
    case A.REMOVE_ARTIST:
      return {
        ...state,
        added: state.added.filter((i) => i !== action.artistId)
      };
    case A.SET_ADDED_ARTISTS:
      return {
        ...state,
        added: action.added
      };
    default:
      return state;
  }
};
