import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as S from './styles';
import { validateFields } from '../../utils/validate';
import { StyledError } from '../../styles/shared-styles';

function ContactForm({ initialValues, fields, initialFieldName = 'name', children, onSubmit }) {
  const validate = (values) => {
    return validateFields(fields, values);
  };

  const renderField = (fieldProps) => {
    const Component = fieldProps.component === 'textarea' ? S.Textarea : S.Input;

    return (
      <Field
        key={fieldProps.name}
        name={fieldProps.name}
        render={({ field }) => (
          <S.FieldGroup withParent={!fieldProps.isVisible}>
            <S.Label>{fieldProps.label}</S.Label>
            <Component
              {...field}
              className={fieldProps.inputClassName}
              disabled={fieldProps.disabled}
              placeholder={fieldProps.placeholder}
              type={fieldProps.type}
            />
            <ErrorMessage name={fieldProps.name} component={StyledError} />
          </S.FieldGroup>
        )}
      />
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={(formProps) => {
        const formPose =
          formProps.values[initialFieldName] && formProps.values[initialFieldName].length > 2
            ? 'visible'
            : 'hidden';

        if (typeof children === 'function') {
          const renderedForm = (
            <S.Form withParent={false} pose={formPose} onSubmit={formProps.handleSubmit}>
              {fields.map(renderField)}
            </S.Form>
          );
          return children({ formPose, renderedForm, formProps });
        }

        return (
          <S.Form withParent={false} pose={formPose} onSubmit={formProps.handleSubmit}>
            {fields.map(renderField)}
            <S.FieldGroup>
              <S.SubmitButton disabled={formProps.isSubmitting} type="submit">
                send
              </S.SubmitButton>
            </S.FieldGroup>
          </S.Form>
        );
      }}
    />
  );
}

export default ContactForm;
